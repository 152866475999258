import React, { useState } from "react";
import StatusList from "./StatusList";
import { Link, useSearchParams } from "react-router-dom";
import useAsync from "helpers/hooks/useAsync";
import { useEffect } from "react";
import fetchData from "helpers/fetchApi";
import { useCookies } from "react-cookie";
import { Icon } from "@iconify/react";
import getUserId from "helpers/hooks/getUserId";
import LoadingScreen from "parts/LoadingScreen";

const nullParams = [null, undefined, '', 0];

export default function TransactionsList(sales) {
  const [cookies, setCookie, removeCookie] = useCookies();

  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter');

  const [loading, setLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(5);

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])


  const transactionsRequest = useAsync();
  const [transactionList, setTransctionList] = useState([]);

  const handleGetTransactionList = async() =>{
    const user = await getUserId(cookies.username, cookies.token);
    
    if(filter == null){
      transactionsRequest.run(fetchData({url: '/sales?user=' + user.id, token: cookies.token}))
    }else{
      transactionsRequest.run(fetchData({url: '/sales??user=' + user.id + '&status=' + filter, token: cookies.token}))
    }
  }

  useEffect(() => {
    handleGetTransactionList()
  }, [transactionsRequest.run, filter])

  useEffect(() => {
    if(transactionsRequest.isIdle == false){
      if(transactionsRequest.isLoading == true){
        setLoading(true);
      }
    }
  }, [transactionsRequest])

  useEffect(() => {
    if(!nullParams.includes(transactionsRequest.data)){
      setLoadProgress(100);
      if(transactionsRequest.data.data.length > 0){
        setTransctionList(transactionsRequest.data.data)
      }
    }
  }, [transactionsRequest.data])

  const statusRequest = useAsync();
  const [statusList, setStatusList] = useState([]);
  useEffect(() => {
   statusRequest.run(fetchData({url: '/get-status-transaction', token:cookies.token}))
  }, [statusRequest.run])

  useEffect(() => {
    if(statusRequest.data != undefined && statusRequest.data != null){
      if(statusRequest.data.data.length > 0){
        setStatusList(statusRequest.data.data);
      }
    }
  }, [statusRequest.data])
  
  const formatDate = (value) => {
      const date = new Date(value);
      const day = date.toLocaleString('default', { day: '2-digit' });
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.toLocaleString('default', { year: 'numeric' });
      return day + ' ' + month + ' ' + year;
  }

  const emptyArea = <>
    <div className="min-h-screen flex items-center flex-col pt-16">
        <Icon icon="mdi:invoice-edit" width={60} />
        <p className="mt-2 text-center">
          "Belum ada pesanan"
        </p>
    </div>
  </>

  const skeletonArea = <>
    <div role="status" class="min-h-screen flex items-center flex-col pt-16 animate-pulse">
      <div className="h-2/3 bg-gray-300">

      </div>
    </div> 
  </>

  return (
   <>
      {
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }
     <div>
      <div>
        <StatusList filter={filter} statusList={statusList} />
      </div>
      <div>
        {
          (statusRequest.isLoading == false && transactionsRequest.isLoading == false) ? (statusList.length > 0 && transactionList.length  > 0) ?
          transactionList.map((item) => {
            const status = statusList.find((thisStatus) => {return thisStatus.id_transaction_status == item.status}) 
            console.log(status)
            let detailTotal = 0;         
            let link = "/transaction/detail/" + item.sales_id; 
            let statusColor;

            if(item.status == 1 || item.status == 6){
              statusColor = {
                backgroundColor: "#ffdede",
                color: "#fa0000",
              }
            }else if(item.status == 4){
              statusColor = {
                backgroundColor: "#dee9ff",
                color: "#0089fa",
              }
            }else if(item.status == 5){
              statusColor = {
                backgroundColor: "#deffe1",
                color: "#00fa7d",
              }
            }else{
              statusColor = {
                backgroundColor: "#FFF0DE",
                color: "#FA8700",
              }
            }

            return (
              <Link to={link}>
                <div className="sm:p-5 py-5 p-3 border border-gray-400 rounded-sm my-6">
                  <div
                    className="grid grid-cols-2 w-full md:mb-2 mb-4 xl:mb-4 items-center"
                    style={{
                      gridAutoRows: "auto",
                    }}
                  >
                    <div className="col-span-1">
                      <h5 className="font-semibold text-gray-900 xl:text-lg md:text-md text-sm">
                        {formatDate(new Date(item.sales_date))}
                      </h5>
                    </div>
                    <div className="col-span-1">
                      <div className="flex justify-end w-full">
                        <div
                          className="inline-block sm:text-sm text-xs font-medium md:mr-2 px-3 py-1 text-center"
                          style={statusColor}
                        >
                          {status?.status_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {item.sales_detail.map((detail) => {
                      detailTotal++;

                      return (
                        <div className="grid grid-cols-6 items-end hover:bg-gray-100 mb-5">
                          <div className="md:col-span-5 col-span-6">
                            <div
                              className="grid grid-cols-6 justify-between md:flex-row w-full relative"
                              style={{
                                gridAutoRows: "auto",
                              }}
                            >
                              <div className="md:col-span-1 sm:col-span-2 col-span-6 sm:w-full sm:h-auto max-h-64 overflow-hidden flex justify-center items-center md:mb-0 mb-4">
                                <img
                                  className="object-cover sm:w-full w-3/4"
                                  src={process.env.REACT_APP_BACKEND_WEB + "/" + detail.thumbnail}
                                  alt={detail.catalog_name}
                                />
                              </div>
                              <div className="md:col-span-5 sm:col-span-4 col-span-6">
                                <div className="flex flex-col justify-between px-4 leading-normal md:w-3/4 w-full">
                                  <div>
                                    <h5 className="mb-3 md:text-xl text-2xl font-semibold tracking-tight text-gray-900 md:text-left text-center">
                                      {detail.catalog_name}
                                    </h5>
                                    <p className="mb-4 font-normal text-gray-400">
                                      Ukuran : {detail.size_code}
                                      <br />
                                      Jumlah : {detail.qty}
                                      <br />
                                      Varian : {detail.product_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="md:col-span-1 col-span-6 h-full">
                            <div className="flex md:items-center items-end justify-end h-full sm:px-0 px-4">
                              <h5 className="mb-2 font-semibold md:text-lg text-md text-gray-700 text-right">
                                Rp {detail.sub_total}
                              </h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {
                      (detailTotal < 3) ? 
                      item.sales_package.map((pakcage) => {
                        if(detailTotal < 3){
                          return (
                            <div className="grid grid-cols-6 items-end hover:bg-gray-100 mb-5">
                            <div className="md:col-span-5 col-span-6">
                              <div
                                className="grid grid-cols-6 justify-between md:flex-row w-full relative"
                                style={{
                                  gridAutoRows: "auto",
                                }}
                              >
                                <div className="md:col-span-1 sm:col-span-2 col-span-6 sm:w-full sm:h-auto max-h-64 overflow-hidden flex justify-center items-center md:mb-0 mb-4">
                                  <img
                                    className="object-cover sm:w-full w-3/4"
                                    src={process.env.REACT_APP_BACKEND_WEB + "/" + pakcage.thumbnail}
                                    alt={pakcage.catalog_name}
                                  />
                                </div>
                                <div className="md:col-span-5 sm:col-span-4 col-span-6">
                                  <div className="flex flex-col justify-between px-4 leading-normal md:w-3/4 w-full">
                                    <div>
                                      <h5 className="mb-3 md:text-xl text-2xl font-semibold tracking-tight text-gray-900 md:text-left text-center">
                                        {pakcage.catalog_name}
                                      </h5>
                                      <p className="mb-4 font-normal text-gray-400">
                                        Jumlah : {pakcage.qty}
                                        <br />
                                        Varian : {pakcage.pakcage_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:col-span-1 col-span-6 h-full">
                              <div className="flex md:items-center items-end justify-end h-full sm:px-0 px-4">
                                <h5 className="mb-2 font-semibold md:text-lg text-md text-gray-700 text-right">
                                  Rp {pakcage.sub_total}
                                </h5>
                              </div>
                            </div>
                          </div>
                          )
                        }
                      })
                      : ''
                    }
                  </div>
                  <div className="border-t border-gray-400 pt-4">
                    <div
                      className="grid grid-cols-5"
                      style={{
                        gridAutoRows: "auto",
                      }}
                    >
                      <div className="md:col-span-3 col-span-2 md:block hidden">
                        <div className="h-full flex items-end">
                          <p className="md:text-md text-xs">
                            Barang disewakan dengan durasi normal 3 x 24 jam proses
                            sewa
                          </p>
                        </div>
                      </div>
                      <div className="md:col-span-2 col-span-5">
                        <div className="flex justify-end">
                          <h5 className="font-semibold md:text-xl text-md">
                            Total : Rp {item.grand_total}
                          </h5>
                        </div>
                      </div>
                      <div className="md:col-span-3 col-span-5 mt-2 md:hidden block">
                        <div className="h-full flex items-end">
                          <p className="md:text-md text-xs">
                            Barang disewakan dengan durasi normal 3 x 24 jam proses
                            sewa
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          }) : emptyArea : skeletonArea
        }
      </div>
    </div>
   </>
  );
}
