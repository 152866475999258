import React from "react";
import Header from "parts/Header";
import Footer from "parts/Footer";
import TermSection from "parts/Terms/TermSection";
import Sitemap from "parts/Sitemap";

export default function Terms() {
  return (
    <>
      <Header theme="black" position="" />
      <TermSection />
      <Sitemap />
      <Footer />
    </>
  );
}
