import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function Logout(){
    const [cookies, setCookies, removeCookies] = useCookies();
    const navigate = useNavigate()

    removeCookies('username')
    removeCookies('full_name')
    removeCookies('user_type')
    removeCookies('token')
    window.location = "/"
}