import React from 'react'

export default function Footer() {
  return (
    <footer className="flex text-center lg:px-32 px-5 sm:px-20 justify-center">
      <p className="text-md font-medium border-t-2 w-full py-6" style={{ borderColor: '#C1C1C1' }}>
        <span>2023 Powered By</span>
        <img src="/images/content/logo_soeta.png" className='inline-block ml-3'/>
      </p>
    </footer>
  )
}
