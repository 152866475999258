import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { initFlowbite } from "flowbite";
import useAsync from "helpers/hooks/useAsync";
import fetchApi from "helpers/fetchApi";
import { Link, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import LoadingScreen from "parts/LoadingScreen";

function formatToIDR(number) {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  });

  return formatter.format(number);
}

export default function ProductList({keyword, category, price, trigger}) {
  const [cookies, setCookies, removeCookies] = useCookies();
  const token = cookies.token;

  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get('category');
  const [loading, setLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(5);

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])

  const productFilters = [
    {
      name: "Among Jawa Barat Untuk Pria",
      size: "S - XL",
      price: 120000,
      rate: 5.0,
      user_rate: 40,
      image: "/images/products/among.png",
    },
  ];

  let tempProducs = [];

  for (let i = 0; i < 9; i++) {
    tempProducs.push(productFilters[0]);
  }

  useEffect(() => {
    initFlowbite();
    return () => {};
  }, []);

  // fetch product
  const products = useAsync();

  useEffect(() => {
    let min_price = null;
    let max_price = null;
    if(price != undefined){
      if(price.length > 0){
        min_price = price[0];
        max_price = price[1];
      }else{
        min_price = null;
        max_price = null;
      }
    }

    products.run(fetchApi({ url: "/katalog-front?keyword=" + keyword + "&category=" + category + "&low_price=" + min_price + "&high_price=" + max_price, token: token}));
  }, [products.run, category, price, keyword, trigger]);

  useEffect(() => {
    if(categoryParam != null){
      products.run(fetchApi({ url: "/katalog-front?category=" + categoryParam, token: token}));
    }
  }, [categoryParam])
  

  const [productData, setProductData] = useState([]);
  

  useEffect(() => {
    setProductData([]);
    if(products.data != undefined && products.data != null){
      if(products.data.status == true && products.data.data.length > 0){
        setLoadProgress(100);
        setProductData(products.data.data);
      }else{
        setLoadProgress(100);
      }
    }
  }, [products.data])
  

  const emptySearch = 
  <>
    <div className="grid grid-cols-1 gap-20 text-center">
    "Sorry, can't found your product now"
    </div>
  </>

  return (
    <>
      {
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }
      {
        (productData.length > 0) ? 
        <div className="grid md:grid-cols-3 grid-cols-2 md:gap-6 xl:gap-4 gap-2">
          {
            productData.map((item, index) => {
              return (
                <div class="relative pb-2" style={{ backgroundColor: "#F5F5F5" }} key={index}>
                  <Link to={"/product/" + item.catalog_id}>
                    <div className="sm:h-60 h-44 overflow-hidden flex items-center">
                      <img class="w-full" src={process.env.REACT_APP_BACKEND_WEB + "/" + item.thumbnail} alt="" />
                    </div>
                    <div class="p-3">
                      <h5
                        class="mb-1 md:text-lg text-md font-medium"
                        style={{
                          color: "#999999",
                        }}
                      >
                        {(item.size_code) ? item.size_code : ''}
                      </h5>
                      <h5 class="mb-2 md:text-xl text-md font-semibold tracking-tight">
                        {item.catalog_name}
                      </h5>
                      <p class="mb-1 md:text-lg text-md font-normal">
                      {
                        (item.packages.length > 0) ? (item.packages[0].price != item.packages[item.packages.length - 1].price) ? formatToIDR(item.packages[0].price) + " s/d \n" + formatToIDR(item.packages[item.packages.length - 1].price) : formatToIDR(item.packages[0].price) : 
                        (
                          (item.product.length > 0) ? (item.product[0].price != item.product[item.product.length - 1].price) ? formatToIDR(item.product[0].price) + " s/d \n" + formatToIDR(item.product[item.product.length - 1].price) : formatToIDR(item.product[0].price) : '-'
                        ) 
                      }
                      </p>
                    </div>
                  </Link>
                </div>
              );
            }) 
          }
        </div>
        : emptySearch
      }
    </>
  );
}