import Footer from 'parts/Footer'
import Header from 'parts/Header'
import SearchResult from 'parts/Products/SearchResult'
import Sitemap from 'parts/Sitemap'
import React from 'react'
import { useLocation } from "react-router-dom";

export default function Products() {
  const myParam = useLocation().search;
  const keyword= new URLSearchParams(myParam).get("keyword");

  return (
    <>
    <Header keyword={keyword}/>
    <SearchResult keyword={keyword}/>
    <Sitemap/>
    <Footer/>
    </>
  )
}
