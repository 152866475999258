import React from "react";

export default function NotificationCard(props) {
  console.log(props);
  return (
    <div className="pt-6 pb-10 px-10 border border-gray-900">
      <div>
        <h1 className="text-2xl font-semibold mb-1">Notifikasi</h1>
        <p className="text-md">
          Anda dapat dapat melihat notifikasi pada halaman ini
        </p>
        <hr className="w-full border-gray-900 my-6" />
      </div>
      <div>
        {Object.keys(props).map((key, i) => {
          return (
            <div
              className="w-full mb-6 border border-gray-800 p-4"
              key={props[key].id}
            >
              <div className="grid grid-cols-12 h-full">
                <div className="col-span-12 md:col-span-9 flex justify-start h-full">
                 <div className="grid grid-cols-12">
                    <div className="md:col-span-3 col-span-12 md:h-32 sm:h-24 h-44 flex md:justify-start justify-center">
                      <img src={props[key].image} className="h-full mr-4" />
                    </div>
                    <p className="md:col-span-9 col-span-12 md:ml-4 md:mt-1 mt-2">{props[key].title}</p>
                 </div>
                </div>
                <div className="col-span-12 md:col-span-3 flex items-center md:justify-end">
                  <a href="" className="py-2 px-4 md:mt-0 mt-4 md:w-auto w-full text-center bg-teal-700 text-white">Lihat</a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
