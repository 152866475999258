import React, { useState } from 'react'
import Header from 'parts/Header'
import Hero from 'parts/HomePage/Hero'
import JustArrived from 'parts/HomePage/JustArrived'
import Clients from 'parts/Clients'
import Sitemap from 'parts/Sitemap'
import Footer from 'parts/Footer'
import useScrollAnchor from 'helpers/hooks/useScrollAnchor'
import useModalDOM from 'helpers/hooks/useModalDOM'
import Services from 'parts/HomePage/Services'
import Categories from 'parts/HomePage/Categories'
import Testimony from 'parts/HomePage/Testimony'
import LoadingScreen from 'parts/LoadingScreen'

export default function HomePage() {
  useScrollAnchor();
  useModalDOM();

  const [refreshHeader, setRefreshHeader] = useState(false);

  const handleRefreshCart = (value) => {
    if(value == true){
      setRefreshHeader(!refreshHeader);
    }
  }
  
  return (
    <>
        <Header theme="black" position="" refreshCart={refreshHeader}/>
        <Hero refreshCart={handleRefreshCart}/>
        <JustArrived/>
        <Services/>
        <Categories/>
        <Testimony/>
        <Sitemap/>
        <Footer/>
    </>
  )
}
