import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Datepicker from "tailwind-datepicker-react"
import moment from "moment/moment";
import { Input } from "@material-tailwind/react";


const proceedPayment = (paymentMethod, virtualAccount) => {
  return (
    <>
      <div className="mb-5">
        <h3 className="px-4 py-2 text-lg font-medium text-gray-900 border border-gray-500">
          No Rekening
        </h3>
        <ul className="border border-gray-500">
          <li>
            <input
              type="text"
              id="va"
              name="va"
              value={virtualAccount}
              className="w-full border-none"
              readOnly
              required
            />
          </li>
        </ul>
      </div>
      <div>
        <h3 className="px-4 py-2 text-lg font-medium text-gray-900 border border-gray-500">
          Jenis Pembayaran
        </h3>
        <ul className="border border-gray-500">
          <li>
            <input
              type="text"
              id="choosedPayment"
              name="payment"
              value={paymentMethod}
              className="w-full border-none"
              readOnly
              required
            />
          </li>
        </ul>
      </div>
    </>
  );
};

const userInfo = {
  name: "Ardi",
  phone: "08324923744",
  address: "Jl. Soeta no 03, Surabaya, Jawa Timur ",
};

const nullParams = [null, "", "null", undefined];

const formatDate = (value) => {
    const date = new Date(value);
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return day + ' ' + month + ' ' + year;
}


function formatDateStrip(d) {
    if(d != null){
      var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    }
}

const today = moment(); 

export default function PaymentDetail({isPay, address, name, phone, getDate, getPaymentType, salesData, eventDate = null, eventTime = null, setImage}) {

  const [show, setShow] = useState()
	const [selectedFormatDate, setSelectedFormatDate] = useState(eventDate)
	const [selectedDate, setSelectedDate] = useState(eventDate)
  const [selectedTime, setSelectedTime] = useState(eventTime)
	const handleChange = (selectedDate = null) => {
    console.log('tesstt')
    console.log(selectedDate)
    if(selectedDate != null){
      setSelectedDate(formatDateStrip(selectedDate))
      setSelectedFormatDate(formatDate(selectedDate))
    }
	}

  const handleChangeTime = (value) => {
    setSelectedTime(value);
  }

  const handleChangeDateTime = (date = null, time = null) => {
    console.log('date time change')
    console.log(date)
    console.log(time)
  
    if(typeof getDate == 'function'){
      getDate(formatDateStrip(new Date(date)));
    }
  }
	const handleClose = (state) => {
		setShow(state)
	}

  useEffect(() => {
    handleChangeDateTime(selectedDate, selectedTime);
  }, [selectedDate, selectedTime])
  

  const options = {
    title: "Pick Rental Date",
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    clearBtnText: "Clear",
    maxDate: "",
    minDate: today,
    theme: {
      background: "",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "bg-gray-200",
      input: "",
      inputIcon: "",
      selected: "hover:text-gray-800",
    },
    datepickerClassNames: "top-12",
    language: "id",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric"
    }
  }

  const handleChangeImage = (e) => {
    if(typeof setImage == 'function'){
      setImage(e.target.files[0])
    }
  }

  const uploadDoc = <>
    <div className="mb-8">
      <h5 className="font-semibold text-gray-900 mb-3">Upload Bukti Pembayaran</h5>
      <div className="">
        <input class="block w-1/2 text-sm text-gray-900 cursor-pointer" onChange={handleChangeImage} id="file_input" type="file"  placeholder="File Belum Dipilih" title="File Belum Dipilih"/>
      </div>
    </div>
  </>

  const handleChangePaymentType = (e) => {
    let paymentType = null;
    const types = document.getElementsByName("paymentType");
    
    for (var i = 0, length = types.length; i < length; i++) {
      if (types[i].checked) {
        paymentType = types[i].value;
        break;
      }
    }

    getPaymentType(paymentType);
  }

  const choosePayment = (
    <>
      <div>
        <h3 className="px-4 py-2 text-lg font-medium text-gray-900 border border-gray-500">
          Jenis Pembayaran
        </h3>
        <ul className="border border-gray-500">
          <li>
            <input
              type="radio"
              id="lunas"
              name="paymentType"
              value="lunas"
              className="hidden peer"
              onClick={(e) => handleChangePaymentType(e)}
              required
            />
            <label
              for="lunas"
              className="inline-flex items-center justify-between w-full px-4 py-2     text-gray-500 peer-checked:bg-gray-200 peer-checked:text-gray-700 hover:text-gray-600 hover:bg-gray-100 cursor-pointer "
            >
              Lunas
            </label>
          </li>
          <li>
            <input
              type="radio"
              id="dp"
              name="paymentType"
              value="dp"
              onClick={(e) => handleChangePaymentType(e)}
              className="hidden peer"
              required
            />
            <label
              for="dp"
              className="inline-flex items-center justify-between w-full px-4 py-2     text-gray-500 peer-checked:bg-gray-200 peer-checked:text-gray-700 hover:text-gray-600 hover:bg-gray-100 cursor-pointer "
            >
              DP 50%
            </label>
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <>
      <div className="mb-8">
        {isPay == true
          ? proceedPayment((salesData == null) ? "Lunas" : (salesData.is_dp == 1) ? "DP 50%" : "Lunas", "01293810984732879 ")
          : choosePayment}
      </div>
      <div className="mb-8">
        <h5 className="font-semibold text-gray-900 mb-3">Tanggal Sewa <span className="text-sm font-medium text-gray-600">*sewa untuk 3 hari</span></h5>
        <div className="md:w-3/4 w-full border border-gray-500 flex justify-between items-center relative">
          {
            (eventDate != null && isPay == true) ?
            <div className="relative w-full">
              <input type="text" className="w-full" placeholder="Select Date" value={formatDate(eventDate)} readOnly />
              <label className="absolute top-1/2 right-2 -mt-2 cursor-pointer" for="event_date">
                <Icon icon="uiw:date"/>
              </label>
            </div>
            : 
            <>
              <Datepicker options={options} className="w-full rounded-none" onChange={handleChange} show={show} setShow={handleClose}>
                <div className="relative">
                  <input type="text" className="w-full" placeholder="Select Date" value={selectedFormatDate} onFocus={() => setShow(true)} readOnly />
                  <label className="absolute top-1/2 right-2 -mt-2 cursor-pointer" for="event_date">
                    <Icon icon="uiw:date"/>
                  </label>
                </div>
              </Datepicker>
            </>
          }
          <div className="border-white border-0">
            <Input type="time" className="border-white border-0" id="time" onChange={(e) => handleChangeTime(e.target.value)}/>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <h5 className="font-semibold text-gray-900 mb-3">Alamat</h5>
        <div className="bg-gray-100 py-3 px-4 mb-3">
          {(!nullParams.includes(name)) ? name : ''} {(!nullParams.includes(phone)) ? "- " + phone : ''}
        </div>
        <div className="bg-gray-100 py-3 px-4 mb-3">{address}</div>
      </div>
      {
        (isPay == true) ? 
        uploadDoc : ''
      }
    </>
  );
}
