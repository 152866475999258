import Footer from 'parts/Footer'
import Header from 'parts/Header'
import Detail from 'parts/ProductDetail/Detail'
import Sitemap from 'parts/Sitemap'
import React, { useState } from 'react'
import { useParams } from "react-router-dom";

export default function ProductDetail() {
  let params = useParams();
  const catalog = params.idc;

  const [refreshHeader, setRefreshHeader] = useState(false);

  const handleRefreshCart = (value) => {
    if(value == true){
      setRefreshHeader(!refreshHeader);
    }
  }
  return (
    <>
    <Header refreshCart={refreshHeader}/>
    <Detail catalog_id={catalog} refreshCart={handleRefreshCart}/>
    <Sitemap/>
    <Footer/>
    </>
  )
}
