import React from "react";
import { Icon } from '@iconify/react';
import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        class="inline-flex items-center md:p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span class="sr-only">Open sidebar</span>
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        class="xl:fixed top-28 xl:left-40 left-20 z-40 2xl:w-80 xl:w-64 xl:h-screen"
        aria-label="Sidebar"
      >
        <div class="h-full xl:px-3 py-4 overflow-y-auto">
          <ul class="space-y-2 font-medium">
            <li>
              <Link
                to="/profile"
                class="flex items-center p-2 text-gray-700 rounded-lg hover:text-gray-900 group"
              >
                <Icon icon="iconamoon:profile" />
                <span class="ml-3 xl:text-lg text-xs">Profil Saya</span>
              </Link>
            </li>
            <li>
              <Link
                to="/forget_password"
                class="flex items-center p-2 text-gray-700 rounded-lg hover:text-gray-900 group"
              >
                <Icon icon="mdi:password-outline" />
                <span class="flex-1 ml-3 whitespace-nowrap xl:text-lg text-xs">Ubah Kata Sandi</span>
              </Link>
            </li>
            <li>
              <Link
                to="/notification"
                class="flex items-center p-2 text-gray-700 rounded-lg hover:text-gray-900 group"
              >
                <Icon icon="iconamoon:notification" />
                <span class="flex-1 ml-3 whitespace-nowrap xl:text-lg text-xs">Notifikasi</span>
              </Link>
            </li>
            <li>
              <Link
                to="/transaction"
                class="flex items-center p-2 text-gray-700 rounded-lg hover:text-gray-900 group"
              >
               <Icon icon="icon-park-outline:transaction-order" />
                <span class="flex-1 ml-3 whitespace-nowrap xl:text-lg text-xs">Pesanan Saya</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
}
