import fetchData from "helpers/fetchApi";
import useAsync from "helpers/hooks/useAsync";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

export default function StatusList({filter, statusList}) {  
  const [searchParams, setSearchParams] = useSearchParams();
  const filterStatus = searchParams.get('filter');


  const handleSelect = (value) =>{
    console.log(value);
    window.location = "?filter=" + value;
  }

  return (
    <div>
      <div className="sm:hidden">
        <label for="tabs" className="sr-only">
          Select status
        </label>
        <select
          id="tabs"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          onChange={(e) => handleSelect(e.target.value)}
        >
          <option value={0}>Semua</option>
          {
            statusList.map((item) => {
              return <option value={item.id_transaction_status} selected={(item.id_transaction_status == filterStatus) ? true : false}>{item.status_name}</option>
            })
          }
          <option value={1}>Batal</option>
        </select>
      </div>
      <ul className="hidden text-sm font-medium text-center text-gray-900 sm:flex" style={{ backgroundColor: "#F5F5F5", minHeight: '60px' }}>
        {
          (statusList.length > 0) ?
          <>
            <li className="w-full">
              <a
                href="/transaction"
                className="p-2 border-b-2 h-full flex items-center justify-center" style={{ color: (filter == null) ? "#56A9B7" : '', borderColor: (filter == null) ? "#56A9B7" : ' '}}
              >
                <span>Semua</span>
              </a>
            </li>
            {
              statusList.map((item) => {
                if(item.id_transaction_status > 1){
                  return <>
                  <li className="w-full">
                      <a
                        href={"?filter=" + item.id_transaction_status}
                        className="p-2 border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 h-full flex items-center justify-center"
                        aria-current="page"
                        style={{ color: (filter == item.id_transaction_status) ? "#56A9B7" : '', borderColor: (filter == item.id_transaction_status) ? "#56A9B7" : ' '}}
                      >
                        <span>{item.status_name}</span>
                      </a>
                    </li>
                  </>
                }
              })
            }
            <li className="w-full">
              <a href="?filter=1" 
                className="p-2 border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 h-full flex items-center justify-center" style={{ color: (filter == 1) ? "#56A9B7" : '', borderColor: (filter == 1) ? "#56A9B7" : ' '}}>
                Batal
              </a>
            </li>
          </> : ''
        }
      </ul>
    </div>
  );
}
