import React, { useEffect, useState } from "react";
import CardList from "./CardList";
import Info from "./Info";
import { Icon } from "@iconify/react";
import { Button } from "flowbite-react";
import fetchApi from "helpers/fetchApi";
import useAsync from "helpers/hooks/useAsync";
import Select from 'react-select'
import { useCookies } from "react-cookie";
import AnimateHeight from "react-animate-height";
import OrderList from "parts/OrderList";
import LoadingScreen from "parts/LoadingScreen";
import formatToIDR from "parts/formatToIDR";
import { Link } from "react-router-dom";

function capitalize(str) {
  // Check if the input is a valid string
  if (typeof str !== 'string') {
    throw new Error('Input must be a string');
  }

  // Convert the entire string to lowercase and split it into words
  const words = str.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the capitalized words back into a string
  return capitalizedWords.join(' ');
}

export default function Transaction({user_id, refreshCart}) {
  // cookies
  const [cookies, setCookies, removeCookies] = useCookies();
  const token = cookies.token;
  
  const [openModal, setOpenModal] = useState("hidden");

  const handleOpenModal = () => {
    setOpenModal("flex");
  };
  const handleCloseModal = () => {
    setOpenModal("hidden");
  };    

  // get cart data
  const cart = useAsync();

  useEffect(() => {
    cart.run(fetchApi({ url: "/cart/" + user_id, token: token}));
  }, [user_id]);

  const [cartList, setCartList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(5);

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])


  useEffect(() => {
    if((cart.data != undefined && cart.data != null)){
      if((cart.data.status == true && cart.data.data.length > 0)){
        // setCartList(cart.data.data)
        setLoadProgress(100);
        const defaultSelectedCart = [];
        cart.data.data.map((item) => {
          if(item.status == 1){
            defaultSelectedCart.push(item)
          }
        })
        setCartList(defaultSelectedCart);
      }else{
        setLoadProgress(100);
        setCartList([])
      }
    }else{
      setCartList([])
    }
  }, [cart.data]);

  const selectedCartRequest = useAsync();
  const handleSelectedCart = (id, type, qty, status) =>{
    if(status == true && qty > 0){
      const statusData = {type: type, status: 1};
      selectedCartRequest.run(fetchApi({ url: "/cart-status/" + id, method:"PUT", body: statusData, token: token}));

      const selectedCart = cart.data.data.find((item) => { return item.index_detail == id && item.type ==  type})
      selectedCart.qty = qty;
      setCartList([...cartList, selectedCart]);
    }else{
      const statusData = {type: type, status: 0};
      selectedCartRequest.run(fetchApi({ url: "/cart-status/" + id, method:"PUT", body: statusData, token: token}));

      const selectedCart = cartList.filter((item) => {
        if (item.index_detail == id && item.type == type) return false
        else return true
       })
      setCartList(selectedCart);
    }
  }

  const handleGetChangeQty = (id, value, type) => {
    let cartChange = [];
    cartList.forEach((item) => {
      if(item.index_detail == id && item.type == type){
        item.qty = value;
      }
      if(item.qty > 0){
        cartChange.push(item);
      }
    });
    setCartList(cartChange);
  }

  // get province
  const provincesRequest = useAsync();
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();

  useEffect(() => {
    provincesRequest.run(fetchApi({ url: "/province", token: token}));
  }, [provincesRequest.run]);



  useEffect(() => {
    if(provincesRequest.data != undefined && provincesRequest.data != null){
      if(provincesRequest.data.status == true && provincesRequest.data.data.length > 0){
        const newProvinces = provincesRequest.data.data.map((item) => {
          const newObj = {
            value : item.id,
            label : capitalize(item.name)
          }

          return newObj;
        });

        setProvinces(newProvinces);
      }else{
        setProvinces([]);
      }
    }else{
      setProvinces([]);
    }
  }, [provincesRequest.data]);

  // get cities
  const citiesRequest = useAsync();
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState();

  const handleChangeProvince = (value) =>{
    citiesRequest.run(fetchApi({ url: "/city/" + value.value, token: token}));
    setSelectedProvince(value);
  }

  useEffect(() => {
    if(citiesRequest.data != undefined && citiesRequest.data != null){
      if(citiesRequest.data.status == true && citiesRequest.data.data.cities.length > 0){
        const newCities = citiesRequest.data.data.cities.map((item) => {
          const newObj = {
            value : item.id,
            label : capitalize(item.name)
          }

          return newObj;
        });

        setCities(newCities);
      }else{
        setCities([]);
      }
    }else{
      setCities([]);
    }
  }, [citiesRequest.data])
  

  // get district
  const districtRequest = useAsync();
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();

  const handleChangeCity = (value) =>{
    districtRequest.run(fetchApi({ url: "/district/" + value.value, token: token}));
    setSelectedCity(value);
  }

  useEffect(() => {
    if(districtRequest.data != undefined && districtRequest.data != null){
      if(districtRequest.data.status == true && districtRequest.data.data.districts.length > 0){
        const newDitstricts = districtRequest.data.data.districts.map((item) => {
          const newObj = {
            value : item.id,
            label : capitalize(item.name)
          }

          return newObj;
        });

        setDistricts(newDitstricts);
      }else{
        setDistricts([]);
      }
    }else{
      setDistricts([]);
    }
  }, [districtRequest.data])
  

  // get village
  const villageRequest = useAsync();
  const [villages, setVillages] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState();

  const handleChangeDistrict = (value) =>{
    villageRequest.run(fetchApi({ url: "/village/" + value.value, token: token}));
    setSelectedDistrict(value);
  }

  useEffect(() => {
    if(villageRequest.data != undefined && villageRequest.data != null){
      if(villageRequest.data.status == true && villageRequest.data.data.villages.length > 0){
        const newVillages = villageRequest.data.data.villages.map((item) => {
          const newObj = {
            value : item.id,
            label : capitalize(item.name)
          }

          return newObj;
        });

        setVillages(newVillages);
      }else{
        setVillages([]);
      }
    }else{
      setVillages([]);
    }
  }, [villageRequest.data])

  const handleChangeVillage = (value) =>{
    setSelectedVillage(value);
  }
  
  const [userAddress, setUserAddress] = useState();

  const handleSetAlamat = (mode) => {
    const post_code = document.getElementById("pos_code").value;
    const address = document.getElementById("detail_address").value;

    let fullAddress = ""; 
    
    if(address){
      fullAddress += address;
    }
 
    if(selectedVillage){
      if(fullAddress != "") {
        fullAddress += ", "
      }
      fullAddress +=  selectedVillage.label
    } 
    
    if(selectedDistrict){
      if(fullAddress != ""){
        fullAddress += ", "
      }
      fullAddress += selectedDistrict.label
    }

    if(selectedCity){
      if(fullAddress != ""){
        fullAddress += ", "
      }
      fullAddress += selectedCity.label
    }

    if(selectedProvince){
      if(fullAddress != ""){
        fullAddress += ", "
      }
      fullAddress +=  selectedProvince.label
    }

    if(post_code){
      if(fullAddress != ""){
        fullAddress += " "
      }
      fullAddress += post_code
    }

    setUserAddress(fullAddress);

    if(mode == 'mobile'){
      window.location = "/payment?address=" + fullAddress + "&name=" + username + "&phone=" + userPhone
    }

    handleCloseModal();
  }

  const [openMobileDetail, setOpenMobileDetail] = useState(0);

  const handleOpenMobileDetail = () => {
    if(openMobileDetail  == 0){
      setOpenMobileDetail('auto');
    }else{
      setOpenMobileDetail(0);
    }
  }

  const [openDeleteCart, setOpenDeleteCart] = useState('hidden');
  const [cartDelete, setCartDelete] = useState({});

  const handleDeleteCart = (id, type) => {
    setCartDelete({id: id, type: (type == 'product') ? 1 : 0});
    setOpenDeleteCart('flex');
  }

  const deleteCartRequest = useAsync();
  const handleDestroyCart = () => {
    deleteCartRequest.run(fetchApi({ url: "/cart", method:"DELETE", body: cartDelete, token: token}));
    console.log(deleteCartRequest);
  }

  useEffect(() => {
    if(deleteCartRequest.data != undefined && deleteCartRequest.data != null){
      if(deleteCartRequest.data.status == true){
        cart.run(fetchApi({ url: "/cart/" + user_id, token: token}));
        setOpenDeleteCart('hidden');
        if(typeof refreshCart == 'function'){
          refreshCart(true);
        }
      }
    }
  }, [deleteCartRequest.data])

  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    if(cartList.length > 0){
      let gt = 0;
      cartList.forEach((item) => {
        gt += (item.qty > 0) ? parseInt(item.qty) * parseInt(item.price) : 0;
      })
      setGrandTotal(gt);
    }else{
      setGrandTotal(0);
    }
  }, [cartList])

  // Get user info
  const userInfoRequest = useAsync();
  const [username, setUsername] = useState(null)
  const [userPhone, setUserPhone] = useState(null)

  useEffect(() => {
    userInfoRequest.run(fetchApi({ url: "/profile?username=" + cookies.username, token: cookies.token}));
  }, [userInfoRequest.run, cookies]);

  useEffect(() => {
    if(userInfoRequest.data != undefined && userInfoRequest.data != null){
      console.log(userInfoRequest.data);
      if(userInfoRequest.data.data != null){
        setUsername(userInfoRequest.data.data.full_name)
        setUserPhone(userInfoRequest.data.data.phone)
      }else{
        setUsername(null)
        setUserPhone(null)
      }
    }else{
      setUsername(null)
      setUserPhone(null)
    }
  }, [userInfoRequest.data])

  return (
    <>
      {
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }

      <div className="pt-32 xl:px-40 lg:px-20 px-5">
        <div className="grid grid-cols-6 gap-8">
          <div className="md:col-span-4 col-span-6">
            <CardList products={cart} getChangeQty={handleGetChangeQty} deleteModal={handleDeleteCart} setSelectedCart={handleSelectedCart}/>
          </div>
          <div className="col-span-2 md:block hidden">
            <Info products={cartList} openModal={handleOpenModal} address={userAddress}/>
          </div>
        </div>
      </div>
      <div
        id="small-modal"
        tabindex="-1"
        className={
          "fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full blur-sm max-h-full justify-center items-center " +
          openModal
        }
        style={{
          backgroundColor: "rgba(68, 85, 85, 0.5)",
          backdropFilter: "blur(2px)",
        }}
      >
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white px-7 py-8">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-gray-900 text-2xl font-medium">Alamat</h1>
              <Icon
                icon="gridicons:cross"
                width="25"
                className="cursor-pointer"
                onClick={handleCloseModal}
              />
            </div>
            <div className="mb-3">
              <label className="text-lg">User Info</label>
              <div className="bg-gray-100 py-3 px-4 mb-3 block md:hidden">{(username != null) ? username : ''} {(userPhone != null) ? "- " + userPhone : ''}</div>
            </div>
            <div className="mb-3">
              <label className="text-lg">Provinsi</label>
              <Select placeholder="Pilih Provinsi" options={provinces} id="province" onChange={handleChangeProvince}/>
            </div>
            <div className="mb-3">
              <label className="text-lg">Kota</label>
              <Select placeholder="Pilih Kota" options={cities} id="city" onChange={handleChangeCity}/>
            </div>
            <div className="mb-3">
              <label className="text-lg">Kecamatan</label>
              <Select placeholder="Pilih Kecamatan" options={districts} id="district" onChange={handleChangeDistrict}/>
            </div>
            <div className="mb-3">
              <label className="text-lg">Kelurahan</label>
              <Select placeholder="Pilih Kelurahan" options={villages} id="villages" onChange={handleChangeVillage}/>
            </div>
            <div className="mb-3">
              <label className="text-lg">Kode Pos</label>
              <input
                className="w-full border border-gray-400 text-md p-3"
                placeholder="Masukkan Kode Pos"
                id="pos_code"
                type="number"
              />
            </div>
            <div className="mb-3">
              <label className="text-lg">Detail Alamat</label>
              <textarea
                className="w-full border border-gray-400 text-md"
                placeholder="Masukkan Detail Alamat"
                id="detail_address"
                rows="3"
              />
            </div>
            <div className="flex justify-end mt-8">
              <Button
                size="md"
                className="px-10 py-1 rounded-sm md:block hidden"
                style={{
                  backgroundColor: "#56A9B7",
                  hover: {
                    backgroundColor: "white",
                    color: "#56A9B7",
                  },
                }}
                onClick={() => handleSetAlamat()}
              >
                <span className="text-lg font-normal">Simpan</span>
              </Button>
              <Button
                size="md"
                className="px-10 py-1 rounded-sm md:hidden block"
                style={{
                  backgroundColor: "#56A9B7",
                  hover: {
                    backgroundColor: "white",
                    color: "#56A9B7",
                  },
                }}
                onClick={() => handleSetAlamat('mobile')}
              >
                <span className="text-lg font-normal">Ajukan</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden block fixed w-full z-30 bottom-0 left-0">
          <AnimateHeight duration={500} height={openMobileDetail}>
            <div className="max-h-80 overflow-y-auto bg-white px-5 py-2 pb-6" style={{ boxShadow:'0px 0px 7px 0px rgba(0,0,0,0.6)' }}>
              <div className="flex justify-between my-3">
                <h3 className="text-lg font-semibold">
                  Rincian Pesanan Penyewaan
                </h3>
                <button
                  onClick={() => handleOpenMobileDetail()}
                >
                  <Icon icon="charm:cross" height={25} />
                </button>
              </div>
              <div>
                <OrderList products={cartList} />
              </div>
            </div>
          </AnimateHeight>
          <div className="grid grid-cols-6 w-full h-14">
            <div className="col-span-4 h-14">
                <div className="border-2 bg-white border-black h-full ">
                  <button className="w-full h-14 flex justify-center items-center text-sm font-semibold" onClick={handleOpenMobileDetail}>
                      <div className="flex justify-between w-full items-center px-5">
                        <div className="flex items-center justify-between w-full h-14">
                          <h3 className="text-sm font-semibold text-center">Total Harga</h3>
                          <h3 className="text-sm font-semibold text-center">{formatToIDR(grandTotal)}</h3>
                        </div>
                        <Icon icon="mingcute:up-line" width={28} className="ml-2"/>
                      </div>
                  </button>
                </div>
            </div>
            <div className="col-span-2 h-14 ">
                <button className="w-full h-full text-white text-sm font-semibold"  style={{ background: "#56A9B7", borderColor: "#56A9B7" }}
                  onClick={handleOpenModal}
                >
                    Ajukan Sewa
                </button>
            </div>
          </div>
      </div>

      <div id="popup-modal" tabindex="-1" className={["bg-gray-900/20 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full", openDeleteCart].join(" ")}>
        <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white shadow">
                <button onClick={() => {setOpenDeleteCart('hidden')}} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="popup-modal">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                <div className="p-4 md:p-5 text-center">
                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500">Are you sure you want to delete this product?</h3>
                    <button onClick={handleDestroyCart} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2">
                        Yes, I'm sure
                    </button>
                    <button onClick={() => {setOpenDeleteCart('hidden')}} data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 ">No, cancel</button>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}
