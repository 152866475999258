import React from 'react'
import Login from './Login'

export default function LoginModal({open,closeModal}) {
  return (
    (open == true) ? 
    <div id="default-modal" tabindex="-1" aria-hidden="true" class="bg-gray-900/40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-screen max-h-full">
        <div class="relative p-4 w-full max-w-lg">
            <div class="relative bg-white rounded-lg shadow h-full">
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                    <h3 class="text-xl font-semibold text-gray-900">
                        Silahkan Login Dulu
                    </h3>
                    <button type="button" onClick={closeModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <div class="p-4 md:p-5 space-y-4">
                    <Login close={closeModal}/>
                </div>
            </div>
        </div>
    </div> : ''
  )
}
