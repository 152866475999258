import React from "react";

const data = {
  id: "Kebijakan 1",
  content: `Lorem ipsum dolor sit amet consectetur. Ut aliquet fringilla libero gravida amet auctor viverra metus massa. Lobortis in augue enim amet gravida nec. Egestas vehicula felis id magna. Et ultrices accumsan nibh quis. Tellus nulla felis mollis id nisl amet lorem semper est. Egestas bibendum senectus ultrices eu sodales bibendum. Aliquet nisi in id in. Duis aliquam pretium donec ultrices adipiscing nisi semper ultricies. Sagittis non dui mattis adipiscing nam quis adipiscing. At placerat duis nascetur nascetur placerat cras feugiat et. Viverra hendrerit at aliquam sapien. Eu arcu mauris in nulla faucibus sagittis.
    Dui luctus quis volutpat nisl augue diam. Semper lacinia id leo morbi sed et phasellus purus tincidunt. Scelerisque sit urna nec sed ultricies tristique metus cursus arcu. Sit id magna cras feugiat semper gravida feugiat tincidunt semper. Neque pulvinar pharetra varius ridiculus sem consectetur sapien dui facilisi. Augue nec pellentesque sit rhoncus netus. Dolor nibh ipsum ornare orci dictum sed consequat leo orci. Aliquam sed amet sed risus id auctor at. Imperdiet sapien pulvinar ac ultrices orci non est. Lobortis porta accumsan a interdum eu nibh erat morbi.
    Facilisi eget a odio eget diam amet et est. Interdum dui tristique sagittis consectetur. Vestibulum adipiscing adipiscing lacus pellentesque diam pharetra lacus non. Blandit aliquam senectus velit senectus. Leo in praesent fames vitae lectus diam ullamcorper dolor luctus. Suspendisse proin nisi facilisi venenatis euismod id.
    Lorem ipsum dolor sit amet consectetur. Ut aliquet fringilla libero gravida amet auctor viverra metus massa. Lobortis in augue enim amet gravida nec. Egestas vehicula felis id magna. Et ultrices accumsan nibh quis. Tellus nulla felis mollis id nisl amet lorem semper est. Egestas bibendum senectus ultrices eu sodales bibendum. Aliquet nisi in id in. Duis aliquam pretium donec ultrices adipiscing nisi semper ultricies. Sagittis non dui mattis adipiscing nam quis adipiscing. At placerat duis nascetur nascetur placerat cras feugiat et. Viverra hendrerit at aliquam sapien. Eu arcu mauris in nulla faucibus sagittis.
Dui luctus quis volutpat nisl augue diam. Semper lacinia id leo morbi sed et phasellus purus tincidunt. Scelerisque sit urna nec sed ultricies tristique metus cursus arcu. Sit id magna cras feugiat semper gravida feugiat tincidunt semper. Neque pulvinar pharetra varius ridiculus sem consectetur sapien dui facilisi. Augue nec pellentesque sit rhoncus netus. Dolor nibh ipsum ornare orci dictum sed consequat leo orci. Aliquam sed amet sed risus id auctor at. Imperdiet sapien pulvinar ac ultrices orci non est. Lobortis porta accumsan a interdum eu nibh erat morbi.
Facilisi eget a odio eget diam amet et est. Interdum dui tristique sagittis consectetur. Vestibulum adipiscing adipiscing lacus pellentesque diam pharetra lacus non. Blandit aliquam senectus velit senectus. Leo in praesent fames vitae lectus diam ullamcorper dolor luctus. Suspendisse proin nisi facilisi venenatis euismod id.`,
};

export default function TermContent({content}) {
  return (
    <div>
      <h1 className="text-2xl font-medium mb-4">{content}</h1>
      <p>{data.content}</p>
    </div>
  );
}
