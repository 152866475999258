import Footer from "parts/Footer";
import Header from "parts/Header";
import PaymentSection from "parts/Payment/PaymentSection";
import Sitemap from "parts/Sitemap";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

export default function Payment() {
  const [searchParams, setSearchParams] = useSearchParams();
  const address = searchParams.get('address');
  const name = searchParams.get('name');
  const phone = searchParams.get('phone');

  return (
    <>
      <Header />
      <PaymentSection address={address} name={name} phone={phone}/>
      <Sitemap />
      <Footer />
    </>
  );
}
