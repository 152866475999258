'use client';
import { Icon } from '@iconify/react';
import { Progress } from 'flowbite-react'
import React, { useEffect, useState } from 'react'

export default function LoadingScreen({progress = 10}) {
    const [progressBar, setProgressBar] = useState(progress)
    let interval = undefined;

    useEffect(() => {
        if(progressBar < 90){
          interval = setInterval(() => {
            setProgressBar((prev) => prev + 1);
          }, 50);
        }else{
            setProgressBar(90);
            clearInterval(interval);  
        }
    }, [progressBar]);


    return (
        <div className='h-screen w-screen bg-gray-900/70 overflow-hidden fixed z-50 top-0 left-0'>
            <div className='h-full w-full flex justify-center items-center overflow-hidden'>
                <div className='flex items-center flex-col w-40'>
                    <Icon icon="mdi:shopping" width={80} color='white' className='mb-6'/>
                    <div class="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mb-3">
                        <div class="bg-blue-600 h-1.5 rounded-full" style={{ width:progressBar+"%" }}></div>
                    </div>
                    <h2 className='text-lg text-white'>Loading...</h2>
                </div>
            </div>
        </div>
  )
}
