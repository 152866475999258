import React from "react";
import Footer from "parts/Footer";
import Header from "parts/Header";
import Sitemap from "parts/Sitemap";
import ProfileSection from "parts/Profile/ProfileSection";

export default function Profile() {
  return (
    <>
      <Header />
      <ProfileSection/>
      <Sitemap />
      <Footer />
    </>
  );
}
