import Footer from "parts/Footer";
import Header from "parts/Header";
import Sitemap from "parts/Sitemap";
import TransactionCard from "parts/TransactionDetail/TransactionCard";
import React from "react";

const sales = {
  customer_name: "Ardi",
  phone: "0983019283091",
  address: "Jl. Soeta Surabaya Rumah Hijau",
  status : 3, 
  sales_date : '4 September 2023',
  delivery_fee : 6000,
  service_fee : 1000, 
  sub_total : 180000,
  grand_total : 187000,
  detail: [
    {
        catalog_name : 'Among Jawa Barat Untuk Pria',
        image: '/images/products/among.png',
        size : 'XL', 
        qty : 10, 
        variant : 'Motif Bunga', 
        sub_total : 120000
    },
    {
        catalog_name : 'Among Jawa Barat Untuk Pria', 
        image: '/images/products/among.png',
        size : 'L', 
        qty : 5, 
        variant : 'Motif Bunga', 
        sub_total : 60000
    }
  ]
};

export default function TransactionDetail() {
  return (
    <>
      <Header />
      <TransactionCard/>
      <Sitemap />
      <Footer />
    </>
  );
}
