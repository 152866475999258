import Sidebar from 'parts/Sidebar'
import React from 'react'
import NotificationCard from './NotificationCard'

export default function NotificationSection(props) {

  console.log(props);
  return (
    <div className="pt-24 xl:px-40 lg:px-20 px-6">
      <div className="grid grid-cols-5 w-full">
        <div className="md:block hidden col-span-1">
            <Sidebar />
        </div>
        <div className="md:col-span-4 col-span-5">
            <NotificationCard {...props}/>
        </div>
      </div>
    </div>
  )
}
