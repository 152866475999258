import useAsync from "helpers/hooks/useAsync";
import React, { useEffect } from "react";
import fetch from "helpers/fetch";
import fetchApi from "helpers/fetchApi";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

var categories = [
  {
    title: "Bridesmain",
    image: "category1.png",
  },
  {
    title: "Busana Preweding",
    image: "category2.png",
  },
  {
    title: "Busana Keluarga",
    image: "category3.png",
  },
  {
    title: "Buku Tamu",
    image: "category4.png",
  },
];

var customColor = {
  gray: "#999",
  bgGray: "#F5F5F5",
};

function Loading({ ratio = {} }) {
  const dummy = [
    {
      id: 1,
      ratio: {
        default: "1/9",
        md: "1/4",
      },
    },
    {
      id: 2,
      ratio: {
        default: "1/9",
        md: "2/2",
      },
    },
    {
      id: 3,
      ratio: {
        default: "1/9",
        md: "2/3",
      },
    },
    {
      id: 4,
      ratio: {
        default: "1/9",
        md: "1/4",
      },
    },
  ];

  return dummy.map((item, index) => {
    return (
      <div
        key={item.id}
        className={`relative card ${
          ratio?.wrapper.default?.[item.ratio.default]
        } ${ratio?.wrapper.md?.[item.ratio.md]}`}
        style={{ height: index === 0 ? 180 : "auto" }}
      >
        <div className="bg-gray-300 rounded-lg w-full h-full">
          <div className={`overlay ${ratio?.meta?.[item.ratio.md]}`}>
            <div className="w-24 h-3 bg-gray-400 mt-3 rounded-full"></div>
            <div className="w-36 h-3 bg-gray-400 mt-2 rounded-full"></div>
          </div>
        </div>
      </div>
    );
  });
}

export default function Categories() {
  const { data, status, error, run, isLoading } = useAsync();
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    run(fetchApi({ url: "/kategori/catalog/", token: cookies.token }));
  }, [run]);

  return (
    <section className="flex py-24 sm:py-16 xl:px-40 lg:px-20 px-6" id="category">
      <div className="container mx-auto">
        <div className="flex flex-start justify-between items-center mb-4">
          <h3 className="text-2xl capitalize font-semibold">Kategori</h3>
          <Link to="/products" className="font-semibold">
            Lihat Semua
          </Link>
        </div>
        <hr className="w-full border border-black mb-6" />
        <div className="overflow-x-hidden px-4" id="carousel">
          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12">
            {
            (data != undefined && data != null) ? (data.status == true && data.data.length > 0) ? data.data.slice(0, 4).map((item) => {
              return (
                <Link to={"/products?category=" + item.category_id}>
                  <div className="lg:max-w-sm rounded overflow-hidden" key={item.title}>
                    <div className="flex items-center justify-center overflow-hidden sm:h-52 h-80">
                      <img
                        className="w-full"
                        src={process.env.REACT_APP_BACKEND_WEB + "/" + item.path_photo}
                        alt={item.category_name}
                      />
                    </div>
                    <div className="px-6 py-4">
                      <div className="font-medium sm:text-xl text-2xl text-center mb-2">
                        {item.category_name}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            }) : <div className="text-center">"No Category Found"</div> : <div className="text-center">"No Category Found"</div>
          }
          </div>
        </div>
      </div>
    </section>
  );
}
