import Sidebar from "parts/Sidebar";
import React from "react";
import ProfileCard from "./ProfileCard";

export default function ProfileSection() {
  return (
    <div className="pt-24 xl:px-40 lg:px-20 px-6">
      <div className="grid grid-cols-5 w-full">
        <div className="md:block hidden col-span-1">
            <Sidebar />
        </div>
        <div className="md:col-span-4 col-span-5">
            <ProfileCard/>
        </div>
      </div>
    </div>
  );
}
