import Footer from "parts/Footer";
import Header from "parts/Header";
import Sitemap from "parts/Sitemap";
import Transaction from "parts/Cart/Transaction";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import getUserId from "helpers/hooks/getUserId";

export default function Cart() {
  const [userId, setUserId] = useState();
  const [cookies, setCookie, removeCookie] = useCookies()

  const handleGetUserId = async() =>{
    const getId = await getUserId(cookies.username, cookies.token);
    if(getId.result == true){
      console.log(getId);
      setUserId(getId.id);
    }
  }

  useEffect(() => {
    window.scrollTo(0,0);
    handleGetUserId()
  }, [])
  

  const [refreshHeader, setRefreshHeader] = useState(false);

  const handleRefreshCart = (value) => {
    if(value == true){
      setRefreshHeader(!refreshHeader);
    }
  }

  return (
    <>
      <Header refreshCart={refreshHeader} />
      <Transaction user_id={userId} refreshCart={handleRefreshCart}/>
      <Sitemap />
      <Footer />
    </>
  );
}
