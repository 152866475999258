import React from "react";
import CardProduct from "./CardProduct";
import { Icon } from "@iconify/react";
import { initFlowbite } from "flowbite";
import { useEffect } from "react";
import { Spinner } from "flowbite-react";

export default function CardList({products, getChangeQty, deleteModal, setSelectedCart}) {
  
  useEffect(() => {
    initFlowbite();
  }, []);

  const handleGetChangeQty = (id, value, type) => {
    if(typeof getChangeQty == 'function'){
      getChangeQty(id, value, type);
    }
  }

  const handleDeleteCart = (id, type) => {
    if(typeof deleteModal == 'function'){
      deleteModal(id, type);
    }
  }

  const handleSetSelectedCart = (id, type, qty, status) => {
    if(typeof setSelectedCart == 'function'){
      setSelectedCart(id, type, qty, status)
    }
  }

  const emptyCart = 
  <>
    <div className="flex flex-col items-center justify-center h-60">
      <Icon icon="bi:cart-x-fill" width={90} className="text-gray-700"/>
      <p className="mt-3">Cart is Empty</p>
    </div>
  </>

  const loadingCart = 
  <>
    <div className="flex flex-col items-center justify-center h-60">
      <Spinner color="info" size="xl" />
      <p className="mt-3">Please wait, loading cart</p>
    </div>
  </>

  return (
    <div>
      {
       (products.isLoading) ? loadingCart : (products.data.data.length > 0) ? products.data.data.map((item, index) => {
          return (
            <CardProduct product={item} getChangeQty={handleGetChangeQty} deleteModal={handleDeleteCart} setSelectedCart={handleSetSelectedCart}/>
          );
        }) : emptyCart
      }
    </div>
  );
}
