import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import Login from "parts/Login";
import Otp from "parts/Otp";
import Register from "parts/Register";
import StorePassword from "parts/StorePassword";
import React, { useState } from "react";
import { useCookies } from "react-cookie";

export default function Hero({refreshCart}) {
  const [isRegister, setIsRegister] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleRegister = (value) => {
    console.log(value);
    setIsRegister(value);
    
    if(value == false){
      setOpenVerif(false)
    }
  };

  const [openVerif, setOpenVerif] = useState(false);
  const [openStorePassword, setOpenStorePassword] = useState(false);

  const handleOpenVerif = (value, data) => {
    console.log(value)
    console.log(data)
    setUserData(data);
    setIsRegister(true);
    if(value == true && data != null && data != undefined){
      setOpenVerif(false);
      setOpenStorePassword(true)
    }else if(value == true){
      setOpenVerif(false);
      setOpenStorePassword(false)
    }else{
      setOpenVerif(true)
      setOpenStorePassword(false)
    }
  };

  const handleOpenFormPassword = (value) => {
    setOpenStorePassword(value)
  }

  const [subtitle, setSubtitle] = useState("Masukkan kode OTP yang anda terima pada nomor");

  const handleChangeEmail = (value) => {
    setSubtitle("Masukkan kode OTP yang anda terima pada " + value);
  }

  const [cookies, setCookie, removeCookie] = useCookies();

  const { height, width } = useWindowDimensions();

  const handleRefreshCart = () => {
    if(typeof refreshCart == 'function'){
      refreshCart(true);
    }
  }

  return (
    <section className="flex items-center hero pt-24 xl:px-40 lg:px-20 px-6" id="hero">
      <div className="md:mx-0 py-16 w-full">
        <div className="grid grid-cols-12">
          <div className="md:col-span-7 col-span-12">
            <div className="inset-0 md:h-88 h-full flex items-center hero-caption text-white">
              <div>
                <h1 className="text-4xl md:text-5xl leading-tight font-bold">
                  <span className="text-green">Persewaan</span>{" "}
                  <br className="" />
                  Baju Among Tamu <br /> & Seserahan
                </h1>
                <h2 className="text-base px-0 md:text-lg md:my-6 my-3 tracking-wide">
                  Busana Among juga perlu diperhatikan
                </h2>
                <div>
                  <a
                    href="#top-products"
                    className="btn-transparent md:text-lg text-2xl font-semibold rounded-lg md:px-4 px-6 md:py-2 py-3 mt-4 inline-block flex-none transition duration-200"
                  >
                    Sewa Sekarang
                  </a>
                </div>
              </div>
            </div>
          </div>
          {
              (cookies.token != undefined && cookies.token != null) ?
              '' :
              (width >= 12800) ? '' :
              <div className="md:col-span-5 col-span-12 md:mt-0 mt-16 md:block hidden">
                <div
                  className="flex items-center bg-white px-7 py-8 w-full xl:w-3/4"
                  style={{ minHeight: "420px" }}
                >
                  {isRegister == true ? (
                    openVerif == true ?
                    <Otp title="Daftar" subTitle={subtitle} toRegister={handleOpenVerif} toLogin={handleRegister} backButton={true} data={userData}/> :
                    (openStorePassword == false) ? <Register toLogin={handleRegister} changeEmail={handleChangeEmail} toVerif={handleOpenVerif}/> : <StorePassword data={userData} toLogin={handleRegister} openFormPassword={handleOpenFormPassword}/>
                  ) : (
                    <Login toRegister={handleRegister} refreshCart={handleRefreshCart} toVerif={handleOpenVerif}/>
                  )}
                </div>
              </div>
            }
        </div>
      </div>
    </section>
  );
}
