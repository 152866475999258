import { Icon } from "@iconify/react";
import { initFlowbite } from "flowbite";
import useAsync from "helpers/hooks/useAsync";
import fetchApi from "helpers/fetchApi";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function formatToIDR(number) {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  });

  return formatter.format(number);
}


export default function CardProduct({product, getChangeQty, deleteModal, setSelectedCart}) {
  useEffect(() => {
    initFlowbite();
    return () => {};
  }, []);

  const [cookies, setCookies, removeCookies] = useCookies();

  const token = cookies.token;

  const [qty, setQty] = useState(product.qty);

  const chartUpdate = useAsync();
  const handleChangeQty = (id, value, type, el) => {
    let thisValue;
    if(value == ''){
      thisValue = 0;
    }else{
      thisValue = parseInt(value);
    }
    setQty(thisValue);
    if(typeof getChangeQty == 'function'){
      getChangeQty(id, thisValue, type)
    }
    
    const element = document.getElementById(el);
    if(element){
      updateCartQty(id, type, value)
      if(isNaN(thisValue)){
        element.value = 0; 
      }else{
        element.value = thisValue;
      }
    }
      
  }

  const handleAddQty = (id, type) => {
    const elementId = "jumlah_" + type+  "_" + id;
    const element = document.getElementById(elementId);
    if(element){
      const newQty = parseInt(element.value) + 1;
      element.value = newQty;
      
      // refresh qty
      updateCartQty(id, type, newQty)
      setQty(newQty);
      if(typeof getChangeQty == 'function'){
        getChangeQty(id, newQty, type)
      }
    } 
  }

  const handleSubQty = (id, type) => {
    const elementId = "jumlah_" + type+  "_" + id;
    const element = document.getElementById(elementId);
    if(element){
      if(parseInt(element.value) > 0){
        const newQty = parseInt(element.value) - 1;
        element.value = newQty;

        // refresh qty
        updateCartQty(id, type, newQty)
        setQty(newQty);
        if(typeof getChangeQty == 'function'){
          getChangeQty(id, newQty, type)
        }
      }else{
        handleDeleteCart(id, type);
      }
    } 
  }

  const updateCartQty = (id, type, value) =>{
    const updateData = {type: (type == 'product') ? 1 : 0, qty: value}
    chartUpdate.run(fetchApi({ url: "/cart/" + id, method:"PUT", body: updateData, token: token}));
  }

  const handleDeleteCart = (id, type) => {
    if(typeof deleteModal == 'function'){
      deleteModal(id, type)
    }
  }

  const handleToggleSelectCart = (id, type) => {
    const el = "checkbox_" + id + "_" + type;
    const element = document.getElementById(el);
    if(element){
      const status = element.checked;
      if(typeof setSelectedCart == 'function'){
        const elQty = document.getElementById("jumlah_" + type + "_" + id);
        let qty = 0;
        if(elQty){
          qty = elQty.value;
        }
        
        setSelectedCart(id, type, parseInt(qty), status)
      }
    }
  }

  useEffect(() => {
    setQty(product.qty)
  }, [product.qty])
  

  return (
    <div
      className="grid grid-cols-12 bg-white border border-gray-300 rounded-sm shadow md:flex-row w-full hover:bg-gray-100 xl:pl-0 p-3 mb-5 relative"
    >
     <div className="col-span-1">
      <div className="h-full flex items-center sm:justify-center">
        <input id={"checkbox_" + product.index_detail + "_" + product.type} type="checkbox" value="" defaultChecked={(product.status == 1) ? true : false} name="bordered-checkbox" className="sm:w-6 sm:h-6 w-4 h-4 bg-gray-100 border-gray-300 focus:ring-teal-600" style={{ color: "#56A9B7", fontSize: 25, borderColor: "#56A9B7"}} onChange={() => handleToggleSelectCart(product.index_detail, product.type)}/>
      </div>
     </div>
     <div className="col-span-11">
        <label for={"checkbox_" + product.index_detail + "_" + product.type}>
          <div className="grid xl:grid-cols-8 grid-cols-6">
            <div className="col-span-2 overflow-hidden">
            <div className="w-full flex justify-center items-center">
              <img
                  className="object-cover rounded-sm w-full xl:h-52 md:h-48 sm:h-60 h-40"
                  src={process.env.REACT_APP_BACKEND_WEB + "/" + product.thumbnail}
                  alt={product.catalog_name}
                />
            </div>
            </div>
            <div className="xl:col-span-6 col-span-4">
              <div className="flex flex-col justify-between px-4 leading-normal w-full relative xl:h-full xl:pb-24">
                <div>
                  <div className="flex justify-between items-start">
                    <h5 className="mb-3 xl:text-2xl text-xl font-medium tracking-tight text-gray-900">
                      {product.catalog_name}
                    </h5>
                    <button type="button" onClick={() => handleDeleteCart(product.index_detail, product.type)}><Icon icon="ic:baseline-clear" width={25} /></button>
                  </div>
                  <p className="mb-4 font-medium xl:text-lg text-gray-400">
                    {
                      (product.size != null && product.size != 0) ? 
                      <>
                        Ukuran : {product.size}
                        <br/>
                      </> : ''
                    }
                    Varian : {product.product_name}
                  </p>
                </div>
                <div className="flex justify-between items-end md:mt-0 mt-3 xl:absolute xl:bottom-3 xl:w-full xl:pr-10">
                  <h5 className="xl:mb-0 mb-2 font-medium xl:text-lg text-gray-700 md:block hidden">
                    SubTotal {(qty > 0) ? formatToIDR(parseInt(qty) * parseInt(product.price)) : formatToIDR(0)}
                  </h5>
                  <div className="text-center w-32">
                    <label
                      for="jumlah"
                      className="mb-2 text-sm font-medium text-gray-800 md:block hidden"
                    >
                      Jumlah
                    </label>
                    <div class="inline-flex rounded-md shadow-sm">
                      <button class="px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-500" onClick={() => handleSubQty(product.index_detail, product.type)}>
                        <Icon icon="memory:minus" />
                      </button>
                      <input
                        type="text"
                        id={"jumlah_" + product.type+  "_" + product.index_detail}
                        onChange={(e) => handleChangeQty(product.index_detail, e.target.value, product.type, "jumlah_" + product.type+  "_" + product.index_detail)}
                        value={qty}
                        className="px-2 py-2 text-center bg-gray-50 border border-gray-500 text-gray-900 text-sm focus:ring-gray-700 focus:border-gray-700 block p-1.1 mx-auto w-full"
                        placeholder=""
                        min="1"
                        required
                      />
                      <button class="px-3 py-2 text-sm font-medium  text-gray-900 bg-white border border-gray-500" onClick={() => handleAddQty(product.index_detail, product.type)}>
                        <Icon icon="memory:plus" />
                      </button>
                  </div>
                  </div>
                </div>
                  <h5 className="mt-8 font-semibold text-gray-700 md:hidden block">
                    SubTotal {(qty > 0) ? formatToIDR(parseInt(qty) * parseInt(product.price)) : formatToIDR(0)}
                  </h5>
              </div>
            </div>
          </div>
        </label>
     </div>
    </div>
  );
}
