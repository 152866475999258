import { Icon } from "@iconify/react";
import { Button } from "flowbite-react";
import React, { useState } from "react";

export default function ChangePassword({ getVerified }) {
  const [showLastPassword, setShowLastPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmassword, setShowConfirmPassword] = useState(false);

  const handleShowLastPassword = () => {
    if (showLastPassword == false) {
      setShowLastPassword(true);
    } else {
      setShowLastPassword(false);
    }
  };

  const handleShowNewPassword = () => {
    if (showNewPassword == false) {
      setShowNewPassword(true);
    } else {
      setShowNewPassword(false);
    }
  };

  const handleShowConfirmPassword = () => {
    if (showConfirmassword == false) {
      setShowConfirmPassword(true);
    } else {
      setShowConfirmPassword(false);
    }
  };

  const handleVerified = () => {
    getVerified(false);
  };

  return (
    <>
      <div>
        <div className="grid grid-cols-4 md:mb-8 mb-4" style={{ gridAutoRows: "auto" }}>
          <div className="sm:col-span-1 col-span-12 sm:mb-5 mb-1">
            <label
              for="last_password"
              className="font-semibold text-md text-gray-400"
            >
              Password lama
            </label>
          </div>
          <div className="sm:col-span-3 col-span-12 sm:mb-5 mb-4">
            <div className="relative">
              <input
                id="last_password"
                name="last_password"
                type={showLastPassword == false ? "password" : "text"}
                className="border border-gray-900 px-3 py-1 w-full"
                placeholder="Masukkan password lama anda"
              />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <Icon
                  icon="mdi:eye-outline"
                  className={showLastPassword == false ? "block" : "hidden"}
                  onClick={handleShowLastPassword}
                  width="23"
                  color="#999999"
                />
                <Icon
                  icon="mdi:eye-off-outline"
                  className={showLastPassword == false ? "hidden" : "show"}
                  onClick={handleShowLastPassword}
                  width="23"
                  color="#999999"
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-1 col-span-12 sm:mb-5 mb-1">
            <label
              for="new_password"
              className="font-semibold text-md text-gray-400"
            >
              Password baru
            </label>
          </div>
          <div className="sm:col-span-3 col-span-12 sm:mb-5 mb-4">
            <div className="relative">
              <input
                id="new_password"
                name="new_password"
                type={showNewPassword == false ? "password" : "text"}
                className="border border-gray-900 px-3 py-1 w-full"
                placeholder="Masukkan password baru anda"
              />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <Icon
                  icon="mdi:eye-outline"
                  className={showNewPassword == false ? "block" : "hidden"}
                  onClick={handleShowNewPassword}
                  width="23"
                  color="#999999"
                />
                <Icon
                  icon="mdi:eye-off-outline"
                  className={showNewPassword == false ? "hidden" : "show"}
                  onClick={handleShowNewPassword}
                  width="23"
                  color="#999999"
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-1 col-span-12 sm:mb-5 mb-1">
            <label
              for="confirm_password"
              className="font-semibold text-md text-gray-400"
            >
              Konfirmasi Password
            </label>
          </div>
          <div className="sm:col-span-3 col-span-12 sm:mb-5 mb-4">
            <div className="relative">
              <input
                id="confirm_password"
                name="confirm_password"
                type={showConfirmassword == false ? "password" : "text"}
                className="border border-gray-900 px-3 py-1 w-full"
                placeholder="Masukkan konfirmasi password baru anda"
              />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <Icon
                  icon="mdi:eye-outline"
                  className={showConfirmassword == false ? "block" : "hidden"}
                  onClick={handleShowConfirmPassword}
                  width="23"
                  color="#999999"
                />
                <Icon
                  icon="mdi:eye-off-outline"
                  className={showConfirmassword == false ? "hidden" : "show"}
                  onClick={handleShowConfirmPassword}
                  width="23"
                  color="#999999"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            size="sm"
            className="py-2 px-4 rounded-none sm:w-auto w-full"
            onClick={handleVerified}
            style={{
              backgroundColor: "#56A9B7",
              hover: {
                backgroundColor: "white",
                color: "#56A9B7",
              },
            }}
          >
            <span className="text-md">Ubah</span>
          </Button>
        </div>
      </div>
    </>
  );
}
