const API_URL = process.env.REACT_APP_BACKEND_API;

const getUserId = async (username, token) => {
    const response = await fetch(API_URL + "/profile?username=" + username, {
        method: 'GET',
        mode: "cors",
        headers: { "Content-Type": "application/json" , "Authorization" : "Bearer " + token},
      });
    const data = await response.json();
    console.log(data);
    
    if(data != undefined && data != null){
        return {result:true, id: data.data.id}
    }else{
        return {result:false}
    }
}

export default getUserId;