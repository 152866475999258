import React, { useEffect } from "react";
import TransactionsList from "./TransactionsList";
import { initFlowbite } from "flowbite";
import Sidebar from "../Sidebar";

export default function TransactionsSection(sales) {
  useEffect(() => {
    initFlowbite();
    return () => {};
  }, []);

  return (
    <div className="pt-24 xl:px-40 lg:px-20 px-6">
      <div className="grid grid-cols-5 w-full">
        <div className="md:block hidden col-span-1">
          <Sidebar/>
        </div>
        <div className="md:col-span-4 col-span-5">
          <TransactionsList {...sales} />
        </div>
      </div>
    </div>
  );
}
