import useAsync from "helpers/hooks/useAsync";
import fetchApi from "helpers/fetchApi";
import OrderButton from "parts/OrderButton";
import OrderList from "parts/OrderList";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export default function Info({ products = [], openModal, address = "" }) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const userInfoRequest = useAsync();
  const [username, setUsername] = useState(null)
  const [userPhone, setUserPhone] = useState(null)

  useEffect(() => {
    userInfoRequest.run(fetchApi({ url: "/profile?username=" + cookies.username, token: cookies.token}));
  }, [userInfoRequest.run, cookies]);

  useEffect(() => {
    if(userInfoRequest.data != undefined && userInfoRequest.data != null){
      console.log(userInfoRequest.data);
      if(userInfoRequest.data.data != null){
        setUsername(userInfoRequest.data.data.full_name)
        setUserPhone(userInfoRequest.data.data.phone)
      }else{
        setUsername(null)
        setUserPhone(null)
      }
    }else{
      setUsername(null)
      setUserPhone(null)
    }
  }, [userInfoRequest.data])
  

  const handleOpenModal = () => {
    if (typeof openModal == "function") {
      openModal(true);
    }
  };

  const buttonParams = {
    url: "/payment?address=" + address + "&name=" + username + "&phone=" + userPhone,
    label: "Ajukan Sewa",
  };

  const nullParams = [null, "", undefined];
  const disabledButton = (nullParams.includes(address) || products.length < 1) ? true : false;

  return (
    <>
      <div>
        <OrderList products={products} />
        <div className="border-b border-gray-300 pb-6 mb-4">
          <h5 className="font-semibold text-gray-900 mb-3">Alamat</h5>
          <div className="bg-gray-100 py-3 px-4 mb-3">{(username != null) ? username : ''} {(userPhone != null) ? "- " + userPhone : ''}</div>
          <input
            type="text"
            className="p-2.5 w-full cursor-pointer"
            placeholder="Masukkan Alamat Anda"
            onClick={handleOpenModal}
            value={address}
            readOnly
          />
        </div>
        <OrderButton buttonParams={buttonParams} disabled={disabledButton}/>
      </div>
    </>
  );
}
