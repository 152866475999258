import { Button } from "flowbite-react";
import React from "react";

export default function VerifUser({getVerified, getPhone}) {

    const handleVerified = () => {
        if(typeof getVerified == 'function'){
            getVerified(true);
        }
    }

    const handleChangePhone = (e, value) => {
      getPhone(value)
    }

  return (
    <div>
      <div className="grid grid-cols-4 mb-8" style={{ gridAutoRows: "auto" }}>
        <div className="sm:col-span-1 col-span-4 sm:mb-0 mb-2">
          <label for="phone" className="font-semibold text-md text-gray-400">
            Email
          </label>
        </div>
        <div className="sm:col-span-3 col-span-4">
          <input
            id="email"
            type="email"
            name="email"
            className="border border-gray-900 px-3 py-1 w-full"
            placeholder="Masukkan email anda"
            onChange={(e) => handleChangePhone(e, e.target.value)}
          />
        </div>
      </div>
      <div>
        <Button
          size="sm"
          className="py-2 px-4 rounded-none sm:w-auto w-full"
          onClick={handleVerified}
          style={{
            backgroundColor: "#56A9B7",
            hover: {
              backgroundColor: "white",
              color: "#56A9B7",
            },
          }}
        >
          <span className="text-md">Kirim</span>
        </Button>
      </div>
    </div>
  );
}
