import React, { useState } from 'react'
import TermSidebar from './TermSidebar'
import TermContent from './TermContent'

export default function TermSection() {

    const [selectedContent, setSelectedContent] = useState();

    const handleClickMenu = (data) => {
        setSelectedContent(data);
    }

  return (
    <>
     <section className="pb-16 pt-28 xl:px-40 lg:px-20 px-6">
        <div className='grid grid-cols-12 gap-10'>
            <div className='md:block hidden col-span-3'>
                <TermSidebar onClickMenu={handleClickMenu}/>
            </div>
            <div className='md:col-span-9 col-span-12'>
                <TermContent content={selectedContent} />
            </div>
        </div>
     </section>
    </>
  )
}
