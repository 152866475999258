import React from "react";
import Footer from "parts/Footer";
import Header from "parts/Header";
import Sitemap from "parts/Sitemap";
import NotificationSection from "parts/Notification/NotificationSection";

const notificationData = [
    {
        id: 1,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
    {
        id: 2,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
    {
        id: 3,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
    {
        id: 4,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
    {
        id: 5,
        image: "/images/products/among.png",
        title: "Pesanan Anda telah selesai dengan kode pesanan 123",
    },
]

export default function Notification() {
  return (
    <>
      <Header />
      <NotificationSection {...notificationData} />
      <Sitemap />
      <Footer />
    </>
  );
}
