import { Icon } from "@iconify/react";
import { Button, rating } from "@material-tailwind/react";
import { initFlowbite } from "flowbite";
import Recomendation from "parts/Recomendation";
import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import fetchApi from "helpers/fetchApi";
import useAsync from "helpers/hooks/useAsync";
import { useCookies } from "react-cookie";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import AnimateHeight from "react-animate-height";
import LoginModal from "parts/LoginModal";
import getUserId from "helpers/hooks/getUserId";
import LoadingScreen from "parts/LoadingScreen";

const imageSrcEmpty = [
  '/images/products/empty_img.jpg',
];

const ratingList = [
  {
    id: "rating_5",
    value: 5,
    total: 40,
  },
  {
    id: "rating_4",
    value: 4,
    total: 20,
  },
  {
    id: "rating_3",
    value: 3,
    total: 7,
  },
  {
    id: "rating_2",
    value: 2,
    total: 1,
  },
  {
    id: "rating_1",
    value: 1,
    total: 1,
  },
];

const reviews = [
  {
    id: 1,
    title: "Bahan Bagus dan Ringan",
    date: "26/09/2023",
    rate: 5,
    note: "Ukuran sesuai, bahan nyaman dipakai dan ringan tidak sesak",
    size: "XL",
  },
  {
    id: 2,
    title: "Busana sesuai dengan gambar",
    date: "27/09/2023",
    rate: 5,
    note: "Busana bagus banget sesuai dengan gambarnya !!",
    size: "L",
  },
];

function formatToIDR(number) {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  });

  return formatter.format(number);
}

export default function Detail({catalog_id, refreshCart}) {
  // fetch product
  const product = useAsync();
  const inputRef = useRef(null);
  const recRef = useRef(null);
  const [recommendationVisible, setRecommendationVisible] = useState(false);
  const [sideBarScroll, setSideBarScroll] = useState({});
  const [recomScroll, setRecomScroll] = useState({});
  const [userId, setUserId] = useState()
  const [cookies, setCookies, removeCookies] = useCookies();

  const token = cookies.token;

  const [initSize, setInitSize] = useState([]);
  const [initVariant, setInitVariant] = useState([]);
  const [availableSize, setAvailableSize] = useState([]);
  const [availableVariant, setAvailableVariant] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(5);

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])

  useEffect(() => {
    product.run(fetchApi({ url: "/katalog-front/" + catalog_id, token: token}));
  }, [product.run, catalog_id]);

  useEffect(() => {
      window. scrollTo(0, 0); 
      if(product.data != undefined && product.data != null){
        if(product.data.status == true){
          setCatalogType(product.data.data.header.type)
          setStockVariant([]);
          setLoadProgress(100);
          if(product.data.data.header.type == 1){
            if(product.data.data.header.product.length > 0){
              setInitVariant([])
              setInitSize([])
              setAvailableVariant([])
              setAvailableSize([])
              product.data.data.header.product.map(el => {
                el.size_product.map((item) => {
                  setStockVariant((stock) => [...stock, {product_id: item.product_id, size: item.size_id, stock: item.last_stock}])
                  setAvailableVariant((variant) => [...variant, item.product_id])
                  setAvailableSize((size) => [...size, item.size_id])
                  setInitVariant((variant) => [...variant, item.product_id])
                  setInitSize((size) => [...size, item.size_id])
                })
              });
            }
          }else if(product.data.data.header.type == 0){
            if(product.data.data.variants.length > 0){
              product.data.data.variants.map((item) => {
                setStockVariant((stock) => [...stock, {product_id: item.product_id, stock: item.stock}])
              })
            }
          }
          if (product.data.data.header.packages.length > 0)  {
            if(product.data.data.header.packages[0].price != product.data.data.header.packages[product.data.data.header.packages.length - 1].price) {
              setCatalogPrice(formatToIDR(product.data.data.header.packages[0].price) + " s/d " + formatToIDR(product.data.data.header.packages[product.data.data.header.packages.length - 1].price))
            } else{
              setCatalogPrice(formatToIDR(product.data.data.header.packages[0].price))
            } 
    
            setSelectedPrice(product.data.data.header.packages[0].price);
          } else{
              if(product.data.data.header.product.length > 0) {
                if(product.data.data.header.product[0].price != product.data.data.header.product[product.data.data.header.product.length - 1].price){
                  setCatalogPrice(formatToIDR(product.data.data.header.product[0].price) + " s/d " + formatToIDR(product.data.data.header.product[product.data.data.header.product.length - 1].price))
                }else{
                  setCatalogPrice(formatToIDR(product.data.data.header.product[0].price))
                }
                setSelectedPrice(product.data.data.header.product[0].price);
              }else{
                setCatalogPrice(formatToIDR(0));
                setSelectedPrice(0);
              }
          } 

          if(product.data.data.images.length > 0){
            setImageList(product.data.data.images);
          }
        }
      }
  }, [product.data])

  const handleGetUserId = async() =>{
    if(cookies.token){
        const getId = await getUserId(cookies.username, cookies.token);
        if(getId.result == true){
          setUserId(getId.id);
        }
    }
  }

  useEffect(() => {
    initFlowbite();

    // const observer = new IntersectionObserver((entries, observer) => {
    //   const entry = entries[0];
    //   console.log('rec in ' + entry.isIntersecting)
    //   if(entry.isIntersecting == false){
    //     setRecommendationVisible(false);
    //   }
    // });

    // observer.observe(recRef.current);
    handleGetUserId()
  }, [cookies]);

  function checkIfInputIsOverlapping() {
    if (inputRef.current && recRef.current) {
      const a = inputRef.current.getBoundingClientRect();
      const b = recRef.current.getBoundingClientRect();

      setSideBarScroll(a);
      setRecomScroll(b);

      // console.log('check')
      // console.log(a);
      // console.log(b);
      // console.log({aa: a.top, ab: b.top + b.height});
      // console.log({ab: a.top + a.height, bb: b.top});
      // console.log(parseInt(a.top));

        if (a.top <= b.top + b.height && a.top + a.height > b.top) {
          setRecommendationVisible(true);
        } else if(parseInt(a.top) <= 112 && parseInt(a.top) > 0 || parseInt(a.top) > parseInt(a.height)){
          setRecommendationVisible(false);
        }
    }
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", checkIfInputIsOverlapping);
    }
    watchScroll();

    return () => {
      window.removeEventListener("scroll", checkIfInputIsOverlapping);
    };
  }, [recommendationVisible, sideBarScroll, recomScroll])
  

  const [openModal, setOpenModal] = useState("hidden");

  const handleOpenModal = () => {
    setOpenModal("flex");
  };
  const handleCloseModal = () => {
    setOpenModal("hidden");
  };

  // index
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const delay = 5000;

  // function resetTimeout() {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  // }

  // useEffect(() => {
  //   resetTimeout();
  //   timeoutRef.current = setTimeout(
  //     () =>
  //       setIndex((prevIndex) =>
  //         prevIndex === imageList.length - 1 ? 0 : prevIndex + 1
  //       ),
  //     delay
  //   );
  //   return () => {
  //     resetTimeout();
  //   };
  // }, [index]);

  const onNextClick = () => {
    console.log(index);
    console.log(imageList.length);
    if (index !== imageList.length - 1) {
      setIndex((idx) => idx + 1);
    }
  };

  const onPreviousClick = () => {
    if (index !== 0) {
      setIndex((idx) => idx - 1);
    }
  };

  const onClickImage = (thisIndex) =>{
    console.log(thisIndex)
    setIndex(thisIndex);
  }

  // open info
  const [openInfo, setOpenInfo] = useState(0);

  const handleOpenInfo = () => {
    if(openInfo  == 0){
      setOpenInfo('auto');
    }else{
      setOpenInfo(0);
    }
  }

  const [catalogPrice, setCatalogPrice] = useState();
  const [selectedPrice, setSelectedPrice] = useState();
  const [imageList, setImageList] = useState([]);
  const [stockVariant, setStockVariant] = useState([]);
  const [catalogType, setCatalogType] = useState()
  const [catalogStock, setCatalogStock] = useState(0);
  const [selectedSize, setSelectedSize] = useState();
  const [selectedVariant, setSelectedVariant] = useState();

  // change size
  const onChangeSize = (e) =>{
    let sizeValue = null;
    const target  = e.target;
    const size = document.getElementsByName("size");
    
    for (var i = 0, length = size.length; i < length; i++) {
      if (size[i].checked) {
        sizeValue = size[i].value;
        break;
      }
    }

    setSelectedSize(sizeValue);



    let stockObject = {};
    if(catalogType == 1){
      if(selectedVariant != undefined){
        stockObject = stockVariant.find((item) => {return (item.product_id == selectedVariant && item.size == sizeValue)});
        if(stockObject){
          setCatalogStock(stockObject.stock)
        }else{
          setCatalogStock(0)
        }
      }
      const variants = stockVariant.filter((item) => item.size == sizeValue).map((item) => item.product_id);
      if(target.checked && selectedSize == sizeValue){
        setAvailableVariant(initVariant)
        setSelectedSize(null);
      }else{
        setAvailableVariant(variants);
      }
    }
  }

  // change variant
  const onChangeVariant = (e, price, product) => {
    setCatalogPrice(formatToIDR(price));
    setSelectedPrice(price);
    let product_id = product;
    const target = e.target;

    if(target.checked && selectedVariant == product_id){
      product_id = null;
      target.checked = false;
      setAvailableSize(initSize)
    }else{
      product_id = product;
    }

    let stockObject = {};
    if(catalogType == 0){
      stockObject = stockVariant.find((item) => {return item.product_id == product_id});
      if(stockObject){
        setCatalogStock(stockObject.stock)
      }else{
        setCatalogStock(0)
      }
    }else if(catalogType == 1){
      if(selectedSize != undefined){
        stockObject = stockVariant.find((item) => {return (item.product_id == product_id && item.size == selectedSize)});
        if(stockObject){
          const sizes = stockVariant.filter((item) => item.product_id == product_id).map((item) => item.size);
          setAvailableSize(sizes);
          setCatalogStock(stockObject.stock)
        }else{
          setCatalogStock(0)
        }
      }else{
        stockObject = stockVariant.find((item) => {return (item.product_id == product_id && item.size == 1)});
        if(stockObject){
          const sizes = stockVariant.filter((item) => item.product_id == product_id).map((item) => item.size);
          setAvailableSize(sizes);
          setCatalogStock(stockObject.stock)
        }else{
          setCatalogStock(0)
        }
      }
    }

   
    setSelectedVariant(product_id);
  } 

  const [inputSewa, setInputSewa] = useState();
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const handleCloseLoginModal = ()=>{
    setOpenLoginModal(false);
  }

  const [showSuccessCart, setShowSuccessCart] = useState(false);
  const [timerSuccessCart, setTimerSuccessCart] = useState(0);

  let interval = undefined;

  useEffect(() => {
    if(showSuccessCart){
      if(timerSuccessCart <= 3){
        interval = setInterval(() => {
          setTimerSuccessCart((prev) => prev + 1);
        }, 1000);
      }else{
        setShowSuccessCart(false);
        clearInterval(interval)
        setTimerSuccessCart(0)
      }
    }
  }, [showSuccessCart, timerSuccessCart])
  

  // handle input
  const cartInput = useAsync();
  const [redirectSewa, setRedirectSewa] = useState(false);
  const handleInput = async(e, redirect = false) => {
    // return;
    if(cookies.token == undefined || cookies.token == null){
      setOpenLoginModal(true);
      return;
    }

    const size = document.getElementsByName("size");
    const variant = document.getElementsByName("variant");
    const price = document.getElementById("price").value;
    let qty;
    if(width >= 1280){
      qty = document.getElementById("jumlah").value;
    }else{
      qty = document.getElementById("jumlah_mobile").value;
    }
    const catalog_id = document.getElementById("catalog_id").value;
    let type = null;

    let sizeValue = null;
    let variantValue = null;
    
    for (var i = 0, length = size.length; i < length; i++) {
      if (size[i].checked) {
        sizeValue = size[i].value;
        break;
      }
    }
    for (var i = 0, length = variant.length; i < length; i++) {
      if (variant[i].checked) {
        variantValue = variant[i].value;
        break;
      }
    }


    const stockObject = stockVariant.find((item) => {return (item.product_id == variantValue)});
    
    if(stockObject){
      if(stockObject.size != 1){
        if(size.length > 0){
          if(sizeValue == null){
            alert("Pilih size terlebih dahulu");
            return;
          }
        }
      }else{
        sizeValue = 1;
      }
    }else{
      sizeValue = 1;
    }

    if(variantValue == null){
      alert("Pilih variant terlebih dahulu");
      return;
    }

    if(parseInt(qty) < 1){
      alert("Qty harus lebih dari 0");
      return;
    }

    const cekStock = stockVariant.find((item) => (parseInt(item.product_id) == parseInt(variantValue) && parseInt(item.size) == parseInt(sizeValue)));
    if(cekStock){
      if(parseInt(cekStock.stock) < parseInt(qty)){
        alert("Maaf, jumlah yang anda input melebihi stock yang tersedia");
        return;  
      }
    }else{
      alert("Maaf, stock tidak tersedia");
      return;
    }

    if (product.data.data.header.packages.length > 0){
      type = 'package';
    }else{
      type = 'product';
    }

    const data = {
      catalog_id : catalog_id,
      price : price, 
      qty : qty,
      variant : variantValue,
      size : sizeValue,
      user_id : userId,
      type : type,
      status: redirect
    }

    setLoading(true);
    cartInput.run(fetchApi({ url: "/cart", method:"POST", body: data, token: token}));

    setRedirectSewa(redirect)
  }

  useEffect(() => {
    if(cartInput.data != undefined && cartInput.data != null){
      if(cartInput.data.status == true){
        setLoading(false);
        setShowSuccessCart(true);
        if(redirectSewa == true){
          setInputSewa(true);
        }else{
          if(typeof refreshCart == 'function'){
            refreshCart(true);
          }
        }
      }
    }
  }, [cartInput.data])
  

  const [previewImageWidth, setPreviewImageWidth] = useState(230);
  const [listImageHeight, setListImageHeight] = useState(20);

  const [openMobileCheckOut, setOpenMobileCheckOut] = useState(0);

  const handleOpenMobileCheckOut = () => {
    if(openMobileCheckOut  == 0){
      setOpenMobileCheckOut('auto');
    }else{
      setOpenMobileCheckOut(0);
    }
  }

  const [mobileCheckoutType, setMobileCheckOutType] = useState('buy');

  const handleMobileCart = () => {
    setMobileCheckOutType('cart');
    handleOpenMobileCheckOut();
  }

  const handleMobileBuy = () => {
    setMobileCheckOutType('buy');
    handleOpenMobileCheckOut();
  }

  const { height, width } = useWindowDimensions();
  useEffect(() => {
    if(width >= 1536){
      setPreviewImageWidth(224)
      setListImageHeight(56)
    }else if(width >= 1280){
      setPreviewImageWidth(192)
      setListImageHeight(56)
    }
    else{
      setPreviewImageWidth(384)
      setListImageHeight(112)
    }
  }, [width])
  

  useEffect(() => {
    if(cartInput.data != undefined && cartInput.data != null){
      if(cartInput.data.status == true && inputSewa == true){
        window.location = '/cart'
       }
    }
  }, [cartInput.data, inputSewa])
  

  const emptyImages = <>
    <div className="grid grid-cols-5 gap-2">
        <div className="col-span-4">
          <div
            className="overflow-hidden relative"
            style={{ width: "230px", height: "230px" }}
          >
            <div
              className="slideshow-slider"
              style={{
                transform: `translate3d(0, ${-index * 230}px, 0)`,
              }}
            >
              {imageSrcEmpty.map(function (item, index) {
                return (
                  <div
                    className="overflow-hidden flex justify-center items-center"
                    style={{ width: "230px", height: "230px" }}
                  >
                    <Icon icon="ic:sharp-image" width={80} />
                  </div>
                );
              })}
            </div>
            <div
              className="absolute top-1/2 right-0"
              style={{ backgroundColor: "#fff", marginTop: "-25px" }}
            >
              <button
                className="left block"
                onClick={onPreviousClick}
              >
                <Icon icon="fe:arrow-up" width={25} />
              </button>
              <button className="right block" onClick={onNextClick}>
                <Icon icon="fe:arrow-down" width={25} />
              </button>
            </div>
          </div>
        </div>
        <div className="overflow-hidden">
          <div
            className="slideshow-slider"
            style={{
              height: "240px",
              transform: `translate3d(0, ${-index * 20}%, 0)`,
            }}
          >
            {imageSrcEmpty.map(function (item, idx) {
              return (
                <div
                  className={[
                    "mb-2 h-10 overflow-hidden border-2 cursor-pointer flex items-center justify-center",
                    index === idx ? "image-active" : "",
                  ].join(" ")}
                >
                  <Icon icon="ic:sharp-image" width={20} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
  </>;
  return (
    <>
      {
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }

      {
        (showSuccessCart == true) ? 
        <div className="h-screen w-screen top-0 left-0 flex justify-center items-center fixed z-50 md:bg-transparent bg-black/30">
          <div className="flex flex-col items-center bg-gray-800/80 w-72 p-4">
            <Icon icon="solar:cart-4-bold-duotone" color="#56a9b7" width={70} className="mb-4"/>
            <p className="text-md text-center text-white">Berhasil memasukkan ke keranjang</p>
          </div>
        </div> : '' 
      }

      <div className="pt-32 xl:px-40 lg:px-20 px-6">
        <div className="grid grid-cols-7 2xl:gap-8 gap-4">
          <div className="xl:col-span-2 col-span-7">
            <div className="2xl:pr-6 pr-2 h-full relative">
              <aside
                id="default-sidebar"
                ref={inputRef} 
                className={[
                  "transition-transform translate-x-0",
                  recommendationVisible == false
                    ? "xl:fixed xl:top-28 xl:left-40 left-20 z-40 2xl:w-80 xl:w-64"
                    : "block bottom-0 absolute  2xl:w-88 xl:w-64",
                ].join(" ")}
                aria-label="Sidebar"
              >
                <div className="bg-white">
                  {/* Image list */}
                  {
                    (imageList.length > 0) ? 
                      <div className="grid xl:grid-cols-5 grid-cols-8 gap-2 mb-3">
                      <div className="xl:col-span-4 sm:col-span-7 col-span-6 xl:block flex justify-center">
                        <div
                          className="overflow-hidden relative 2xl:w-56 2xl:h-56 xl:w-48 xl:h-48 w-96 h-96"
                        >
                        
                          <div
                            className="slideshow-slider"
                            style={{
                              transform: `translate3d(0, ${-index * previewImageWidth}px, 0)`,
                            }}
                          >
                            {imageList.map(function (item, index) {
                              return (
                                <div
                                  className="overflow-hidden flex justify-center items-center 2xl:w-56 2xl:h-56 xl:w-48 xl:h-48 sm:w-96 w-auto h-96"
                                  key={index}
                                >
                                  <img
                                    className="w-full"
                                    src={process.env.REACT_APP_BACKEND_WEB + "/" + item.image}
                                    key={index}
                                  />
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className="absolute top-1/2 right-0"
                            style={{ backgroundColor: "#fff", marginTop: "-25px" }}
                          >
                            <button
                              className="left block"
                              onClick={onPreviousClick}
                            >
                              <Icon icon="fe:arrow-up" className="sm:w-6 sm:h-6 w-9 h-9" />
                            </button>
                            <button className="right block" onClick={onNextClick}>
                              <Icon icon="fe:arrow-down" className="sm:w-6 sm:h-6 w-9 h-9" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-1 col-span-2 overflow-hidden">
                        <div
                          className="slideshow-slider"
                          style={{
                            height: previewImageWidth + "px",
                            transform: `translate3d(0, ${-index * listImageHeight}px, 0)`,
                          }}
                        >
                          {imageList.map(function (item, idx) {
                            return (
                              <div
                                className={[
                                  "mb-2 xl:h-14 h-28 overflow-hidden border-2 cursor-pointer flex justify-center items-center",
                                  index === idx ? "image-active" : "",
                                ].join(" ")}
                              >
                                <img className="w-full" src={process.env.REACT_APP_BACKEND_WEB + "/" + item.image} onClick={() => onClickImage(idx)}/>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div> : emptyImages 
                  }
                  

                  {
                    (width > 1280) ?
                    <>
                      {/* Size */}
                      { 
                        (product.error == null && product.data != undefined && product.data != null) ? (product.data.status == true && product.data.data.sizes.length > 0 && product.data.data.sizes[0] != null) ?
                          <div className="relative xl:block hidden">
                          <h3 className="mb-0.5 text-md font-medium text-gray-900">
                            Ukuran
                          </h3>
                          <ul className="w-full">
                            {
                              product.data.data.sizes.map(function (item) {
                                  return (
                                    <li className={["mr-4 mb-3", (item.size_id == 1) ? "hidden" : "inline-block"].join(" ")}>
                                      <input
                                        type="radio"
                                        id={item.size_id + '_' +  item.size.toLocaleLowerCase().split(" ").join("_")}
                                        name="size"
                                        value={item.size_id}
                                        className="hidden peer"
                                        checked={item.size_id == selectedSize}
                                        disabled={(availableSize.includes(parseInt(item.size_id))) ? false : true}
                                        onClick={(e) => onChangeSize(e)}
                                        required
                                      />
                                      <label
                                        for={item.size_id + '_' +  item.size.toLocaleLowerCase().split(" ").join("_")}
                                        className="inline-flex items-center justify-center text-sm rounded-sm w-full p-1.5 border-2 border-gray-400 text-gray-900 cursor-pointer hover:bg-dark-green hover:text-white peer-checked:bg-dark-green peer-checked:text-white peer-disabled:bg-gray-400 peer-disabled:text-gray-900"
                                        style={{ minWidth: "50px" }}
                                      >
                                        <div className="block">
                                          <div className="w-full">{item.size}</div>
                                        </div>
                                      </label>
                                    </li>
                                  );
                              })
                            }
                          </ul>
                        </div> : '' : ''
                      }

                      {/* Variant */}
                      {
                        (product.error == null && product.data != undefined && product.data != null) ? (product.data.status == true && product.data.data.variants.length > 0) ?
                        <div className="relative xl:block hidden">
                        <h3 className="mb-1 text-md font-medium text-gray-900">
                          Varian
                        </h3>
                        <ul className="w-full">
                          {product.data.data.variants.map(function (item) {
                            return (
                              <li className="inline-block mr-4 mb-3">
                                <input
                                  type="radio"
                                  id={item.product_id + '_' + item.product_name.toLocaleLowerCase().split(" ").join("_")}
                                  name="variant"
                                  value={item.product_id}
                                  className="hidden peer"
                                  required
                                  checked={item.product_id == selectedVariant}
                                  disabled={(availableVariant.includes(item.product_id)) ? false : true}
                                  onClick={(e) => onChangeVariant(e,item.price, item.product_id)}
                                />
                                <label
                                  for={item.product_id + '_' + item.product_name.toLocaleLowerCase().split(" ").join("_")}
                                  className="inline-flex items-center justify-center text-sm rounded-sm w-full p-1.5 border-2 border-gray-400 text-gray-900 cursor-pointer hover:bg-dark-green hover:text-white peer-checked:bg-dark-green peer-checked:text-white peer-disabled:bg-gray-400 peer-disabled:text-gray-900"
                                  style={{ minWidth: "50px" }}
                                >
                                  <div className="block">
                                    <div className="w-full">{item.product_name}</div>
                                  </div>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div> : '' : ''
                      }
                    </> : ''
                  }
                  

                  {/* Jumlah */}
                  <div className="relative mb-3 xl:block hidden">
                    <div className="mb-1 text-md font-medium text-gray-900">
                      <label for="jumlah">Jumlah</label>
                    </div>
                    <div className="flex align-middle">
                      <input
                        type="number"
                        id="jumlah"
                        name="jumlah"
                        min="0"
                        className="w-28 mr-3 text-sm"
                        defaultValue="0"
                        max={parseInt(catalogStock)}
                      />
                      <div className="flex items-center">
                        <h5 className="h5 text-sm text-gray-500 mb-0 align-middle">
                          Stok : {catalogStock}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="relative xl:block hidden">
                    <div
                      className="grid grid-cols-2 gap-2"
                      style={{ gridAutoRows: "auto" }}
                    >
                      <div className="">
                        <Button
                          className="w-full rounded-sm text-md font-medium sm:px-1"
                          style={{
                            backgroundColor: "#56A9B7",
                            border: "2px solid #56A9B7",
                          }}
                          onClick={(e) => handleInput(e)}
                        >
                          +Keranjang
                        </Button>
                      </div>
                      <div className="">
                        {/* <Link to="/cart"> */}
                          <Button className="w-full rounded-sm text-md font-medium border-2 border-gray-400 bg-white text-gray-900" onClick={(e) => handleInput(e, true)}>
                            Sewa
                          </Button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
          <div className="xl:col-span-5 col-span-7 min-h-screen">
            {
              (product.error == null && product.data != undefined && product.data != null) ? (product.data.status == true) ? 
              <div className="xl:pr-6">
                <div className="mb-6">
                <input type="hidden" id="catalog_id" value={product.data.data.header.catalog_id} />
                <input type="hidden" id="price" value={selectedPrice} />
                  <h1 className="h1 sm:text-3xl text-2xl font-bold mb-2" id="catalog_name">{product.data.data.header.catalog_name}</h1>
                  <div className="flex justify-between items-center">
                    <h1 className="h1 sm:text-2xl text-lg font-semibold">
                      {catalogPrice}
                    </h1>
                  </div>
                </div>

                <div className="mb-6">
                  <p className="break-all whitespace-break-spaces sm:text-lg text-md font-normal">
                    {product.data.data.header.description}
                  </p>
                </div>
                <div>
                  <div id="accordion-open" className="border-y-2 border-gray-400">
                    <h2 id="info-collapse-heading">
                      <button
                        type="button"
                        className="flex items-center justify-between w-full p-1 py-3 font-medium text-left !bg-white !text-gray-900"
                        onClick={handleOpenInfo}
                        aria-controls="info-collapse-body"
                      >
                        <span className="text-gray-900 sm:text-xl text-lg">
                          Info Penting
                        </span>
                        { 
                          (openInfo == 0) ? 
                          <Icon icon="icon-park:down" /> : <Icon icon="icon-park:up" />
                        }
                      </button>
                    </h2>
                    <AnimateHeight duration={500} height={openInfo}>
                      <div
                        id="info-collapse-body"
                      >
                        <div className="p-3">
                          <p className="break-all whitespace-break-spaces sm:text-lg text-md font-normal">{product.data.data.header.notes}</p>
                        </div>
                      </div>
                    </AnimateHeight>
                  </div>
                </div>
                {/* Rating */}
                {/* <div>
                  <h2 className="sm:text-xl text-lg font-medium text-gray-900 my-6">
                    Rating
                  </h2>
                  <div className="grid grid-cols-5 gap-4 mb-6">
                    <div className="sm:col-span-1 col-span-2">
                      {ratingList.map(function (item) {
                        return (
                          <div className="flex items-center mb-3" key={item.id}>
                            <svg
                              className="w-6 h-6 text-yellow-300 mr-1"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 22 20"
                            >
                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                            </svg>
                            <p className="ml-2 mr-1 sm:text-xl text-md font-normal text-gray-900">
                              {item.value}
                            </p>
                            <a
                              href="#"
                              className="sm:text-xl text-md font-normal text-gray-900 no-underline hover:no-underline"
                            >
                              ({item.total})
                            </a>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sm:col-span-4 col-span-3">
                      <div className="flex items-center sm:mb-3 mb-1">
                        <svg
                          className="sm:w-16 sm:h-16 w-12 h-12 text-yellow-300 mr-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 22 20"
                        >
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                        </svg>
                        <p className="ml-2 sm:text-5xl text-4xl font-normal text-gray-900">
                          4.6
                          <span className="text-gray-600 sm:text-3xl text-xl ml-1">
                            /5.0
                          </span>
                        </p>
                      </div>
                      <div className="sm:mb-8 mb-4">
                        <p className="text-gray-500 sm:text-lg text-md">
                          69 rating dan 2 Ulasan
                        </p>
                      </div>
                      <div>
                        <Button
                          className="py-2 bg-white text-gray-900 border border-gray-700 rounded-sm sm:text-2xl text-xl font-semibold"
                          onClick={handleOpenModal}
                        >
                          Tulis Ulasan
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="border-y py-3 border-gray-900">
                    <h2 className="sm:text-xl text-lg font-medium text-gray-900">
                      2 Ulasan
                    </h2>
                  </div>
                  <div>
                    {reviews.map(function (item) {
                      return (
                        <div className="py-5 sm:pr-12 border-b border-gray-900">
                          <div>
                            <div className="flex align-middle justify-between mb-1">
                              <h1 className="sm:text-2xl text-lg font-semibold">
                                {item.title}
                              </h1>
                              <div className="flex items-center">
                                <svg
                                  className="sm:w-6 sm:h-6 w-4 h-4 text-yellow-300 sm:mr-1 mr-0.5"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 22 20"
                                >
                                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                </svg>
                                <p className="ml-2 sm:text-xl text-md font-normal text-gray-900">
                                  {item.rate}
                                </p>
                              </div>
                            </div>
                            <h2 className="sm:text-lg text-md font-normal text-gray-500">
                              {item.date}
                            </h2>
                            <p className="my-3 font-normal sm:text-lg text-md">
                              {item.note}
                            </p>
                            <p className="font-medium sm:text-md text-sm">
                              Ukuran : {item.size}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div> */}
              </div> 
              : '' : ''
            }
          </div>
        </div>
      </div>
      <div ref={recRef}>
        <Recomendation />
      </div>
      <div
        id="small-modal"
        tabindex="-1"
        className={
          "fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full blur-sm max-h-full justify-center items-center " +
          openModal
        }
        style={{
          backgroundColor: "rgba(68, 85, 85, 0.5)",
          backdropFilter: "blur(2px)",
        }}
      >
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white px-7 py-8">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-gray-900 text-2xl font-medium">Ulasan</h1>
              <Icon
                icon="gridicons:cross"
                width="25"
                className="cursor-pointer"
                onClick={handleCloseModal}
              />
            </div>
            <div className="mb-6">
              <p className="mb-2 text-lg">Bagaimana kualitas produk kami ?</p>
              <div className="flex items-center">
                <Icon icon="ph:star-fill" color="#ffec42" width="20" />
                <Icon icon="ph:star-fill" color="#ffec42" width="20" />
                <Icon icon="ph:star-fill" color="#ffec42" width="20" />
                <Icon icon="ph:star-fill" color="#ffec42" width="20" />
                <Icon icon="ph:star-fill" color="#F5F5F5" width="20" />
                <p className="ml-4" style={{ color: "#999999" }}>
                  Baik
                </p>
              </div>
            </div>
            <div className="mb-8">
              <p className="mb-2 text-lg">Berikan ulasan pada produk</p>
              <textarea
                className="border border-gray-400 w-full focus:border-gray-700 text-md"
                rows="6"
                placeholder="Tulis ulasan pada layanan dan produk kami"
              />
            </div>
            <div className="flex justify-end">
              <Button
                size="md"
                className="px-10 py-2 rounded-sm"
                style={{
                  backgroundColor: "#56A9B7",
                  hover: {
                    backgroundColor: "white",
                    color: "#56A9B7",
                  },
                }}
                onClick={handleCloseModal}
              >
                <span className="text-lg font-normal">Kirim</span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {
        (width <= 1280) ?
        <div className="xl:hidden block fixed w-full z-30 bottom-0 left-0">
                <AnimateHeight duration={500} height={openMobileCheckOut}>
                  <div className="max-h-80 overflow-y-auto bg-white px-5 py-2 pb-6">
                    <div className="flex justify-end mb-3">
                      <button
                        onClick={() => handleOpenMobileCheckOut()}
                      >
                        <Icon icon="charm:cross" height={25} />
                      </button>
                    </div>
                    {/* Size */}
                    { 
                        (product.error == null && product.data != undefined && product.data != null) ? (product.data.status == true && product.data.data.sizes.length > 0 && product.data.data.sizes[0] != null) ?
                          <div className="relative">
                          <h3 className="mb-0.5 text-md font-medium text-gray-900">
                            Ukuran
                          </h3>
                          <ul className="w-full">
                            {
                              product.data.data.sizes.map(function (item) {
                                return (
                                  <li className={["mr-4 mb-3", (item.size_id == 1) ? "hidden" : "inline-block"].join(" ")}>
                                    <input
                                      type="radio"
                                      id={item.size_id + '_mobile_' +  item.size.toLocaleLowerCase().split(" ").join("_")}
                                      name="size"
                                      value={item.size_id}
                                      className="hidden peer"
                                      required
                                      checked={item.size_id == selectedSize}
                                      disabled={(availableSize.includes(parseInt(item.size_id))) ? false : true}
                                      onClick={(e) => onChangeSize(e)}
                                    />
                                    <label
                                      for={item.size_id + '_mobile_' +  item.size.toLocaleLowerCase().split(" ").join("_")}
                                      className="inline-flex items-center justify-center text-sm rounded-sm w-full p-1.5 border-2 border-gray-400 text-gray-900 cursor-pointer hover:bg-dark-green hover:text-white peer-checked:bg-dark-green peer-checked:text-white peer-disabled:bg-gray-400 peer-disabled:text-gray-900"
                                      style={{ minWidth: "50px" }}
                                    >
                                      <div className="block">
                                        <div className="w-full">{item.size}</div>
                                      </div>
                                    </label>
                                  </li>
                                );
                              })
                            }
                          </ul>
                        </div> : '' : ''
                      }

                      {/* Variant */}
                      {
                        (product.error == null && product.data != undefined && product.data != null) ? (product.data.status == true && product.data.data.variants.length > 0) ?
                        <div className="relative">
                        <h3 className="mb-1 text-md font-medium text-gray-900">
                          Varian
                        </h3>
                        <ul className="w-full">
                          {product.data.data.variants.map(function (item) {
                            return (
                              <li className="inline-block mr-4 mb-3">
                                <input
                                  type="radio"
                                  id={item.product_id + '_mobile_' + item.product_name.toLocaleLowerCase().split(" ").join("_")}
                                  name="variant"
                                  value={item.product_id}
                                  className="hidden peer"
                                  required
                                  checked={item.product_id == selectedVariant}
                                  disabled={(availableVariant.includes(item.product_id)) ? false : true}
                                  onClick={(e) => onChangeVariant(e,item.price, item.product_id)}
                                />
                                <label
                                  for={item.product_id + '_mobile_' + item.product_name.toLocaleLowerCase().split(" ").join("_")}
                                  className="inline-flex items-center justify-center text-sm rounded-sm w-full p-1.5 border-2 border-gray-400 text-gray-900 cursor-pointer hover:bg-dark-green hover:text-white peer-checked:bg-dark-green peer-checked:text-white peer-disabled:bg-gray-400 peer-disabled:text-gray-900"
                                  style={{ minWidth: "50px" }}
                                >
                                  <div className="block">
                                    <div className="w-full">{item.product_name}</div>
                                  </div>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div> : '' : ''
                      }

                      {/* Jumlah */}
                      <div className="relative mb-3">
                        <div className="mb-1 text-md font-medium text-gray-900">
                          <label for="jumlah">Jumlah</label>
                        </div>
                        <div className="flex align-middle">
                          <input
                            type="number"
                            id="jumlah_mobile"
                            name="jumlah"
                            min="0"
                            className="w-28 mr-3 text-sm"
                            defaultValue="0"
                          />
                          <div className="flex items-center">
                            <h5 className="h5 text-sm text-gray-500 mb-0 align-middle">
                              Stok : {catalogStock}
                            </h5>
                          </div>
                        </div>
                      </div>
                  </div>
                </AnimateHeight>
                <div className="grid grid-cols-6 w-full h-14">
                  {
                    (openMobileCheckOut == 0) ? 
                    <>
                      <div className="col-span-2 h-14">
                          <button className="w-full h-full flex justify-center items-center border-2" style={{ background: "#56A9B7", borderColor: "#56A9B7" }} onClick={handleMobileCart}>
                              <Icon icon="memory:plus" width={23} color="white"/>
                              <Icon icon="mdi:cart" width={28} color="white"/>
                          </button>    
                      </div>
                      <div className="col-span-4 h-14 ">
                          <button className="w-full h-full bg-white border-2 border-black font-medium" onClick={handleMobileBuy}>
                              Sewa
                          </button>
                      </div>
                    </> : 
                    <div className="col-span-6 h-14 ">
                      <button className="w-full h-full border-2 text-white font-medium" 
                      onClick={(e) => handleInput(e, (mobileCheckoutType == 'buy') ? true : false)} 
                      style={{ background: "#56A9B7", borderColor: "#56A9B7" }}>
                          {(mobileCheckoutType == 'buy') ? 'Beli Sekarang' : 'Masukkan Keranjang'}
                      </button>
                  </div>
                  }
                </div>
            </div> : ''
      }
      

      <LoginModal open={openLoginModal} closeModal={handleCloseLoginModal}/>
    </>
  );
}
