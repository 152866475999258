import React, { useEffect, useState } from "react";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import useAsync from "helpers/hooks/useAsync";
import fetchApi from "helpers/fetchApi";
import LoadingScreen from "./LoadingScreen";

export default function Register({ toLogin, changeEmail, toVerif }) {
  const handleLogin = () => {
    if (typeof toLogin == "function") {
      toLogin(false);
    }
  };

  const handleChangeEmail = (e, value) => {
    if (typeof changeEmail == "function") {
      changeEmail(value);
    }
  };

  const handleVerif = (value, data) => {
    if (typeof toVerif == "function") {
        toVerif(value, data);
    }
  }

  const [errorRegister, setErrorRegister] = useState(null);

  const userInput = useAsync();
  const [loading, setLoading] = useState(false);
  const [loadProgress, setLoadProgress] = useState(5);

  useEffect(() => {
    console.log('cjeck');
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])

  function validatePhone(phone) {
    const regex = /^\(?([0-9]{4})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4}|[0-9]{5})$/;
    return regex.test(phone);
  }

  const handleRegister = async(e) => {
    e.preventDefault();
    setLoading(true);
    const email = document.getElementById("email").value;
    const username = document.getElementById("username").value;
    const full_name = document.getElementById("full_name").value;
    const phone = document.getElementById("phone").value;

    const checkPhone  = validatePhone(phone);


    if(checkPhone == false){
      setLoadProgress(100);
      setErrorRegister("Not Valid Phone Number");
      return;
    }

    const data = {
      email : email,
      username : username,
      full_name : full_name,
      phone : phone
    };

    await userInput.run(fetchApi({ url: "/auth/register", method:"POST", body: data}));
    
    if(userInput.error){
      setLoadProgress(100);
      setErrorRegister(userInput.error);
    }
  }

  const otpRequest = useAsync();

  useEffect(() => {
    if(userInput.data != undefined && userInput.data != null){
      if(userInput.data.status == true){
        setLoadProgress(100);
        const user_id = userInput.data.user_id;
        const data = {
          user_id: user_id
        }
        otpRequest.run(fetchApi({ url: "/generate-otp", method:"POST", body: data}));
      }else{
        setLoading(false)
        setErrorRegister(userInput.data.message)
      }
    }
  }, [userInput.data])

  useEffect(() => {
    if(otpRequest.data != undefined && otpRequest.data != null){
      if(otpRequest.data.status == true){
        const data = {
          user_id : otpRequest.data.data.user_id,
          exp : otpRequest.data.data.expire_at
        }

        handleVerif(false, data)
        console.log(data);
      }
    }
  }, [otpRequest.data])
  

  return (
    <>
      {
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }
      <div>
        <h1 className="text-center text-2xl font-medium mb-5">Daftar</h1>
          {
            (errorRegister != null) ? 
            <>
            <div class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span class="sr-only">Error</span>
                <div>
                  <span class="font-medium">Error!</span> {errorRegister}.
                </div>
              </div>
            </> : ''
          }
        <div>
          <form onSubmit={handleRegister}>
            <input
              type="email"
              id="email"
              class="text-gray-800 w-full border border-gray-400 font-light mb-3"
              placeholder="Email"
              required
              onChange={(e) => handleChangeEmail(e, e.target.value)}
            />
            <input
              type="text"
              id="username"
              class="text-gray-800 w-full border border-gray-400 font-light mb-3"
              placeholder="Username"
              required
            />
            <input
              type="text"
              id="full_name"
              class="text-gray-800 w-full border border-gray-400 font-light mb-3"
              placeholder="Nama"
              required
            />
            <input
              type="tel"
              id="phone"
              pattern="/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/"
              class="text-gray-800 w-full border border-gray-400 font-light"
              placeholder="Nomor Telepon"
              required
            />
            <Button
              type="submit"
              size="sm"
              className="w-full py-2 rounded-sm mt-5 mb-2"
              style={{
                backgroundColor: "#56A9B7",
                hover: {
                  backgroundColor: "white",
                  color: "#56A9B7",
                },
              }}
            >
              <span className="text-base font-light">Berikutnya</span>
            </Button>
          </form>
        </div>
        <div className="mt-5">
          <p className="text-center text-gray-500" style={{ fontSize: "13px" }}>
            Sudah Memiliki Akun?{" "}
            <span className="text-blue-800 cursor-pointer" onClick={handleLogin}>
              Login
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
