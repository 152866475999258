import React, { useEffect, useState } from "react";
import Sidebar from "parts/Sidebar";
import VerifUser from "./VerifUser";
import ChangePassword from "./ChangePassword";
import Otp from "parts/Otp";
import { initFlowbite } from "flowbite";

export default function ForgetCard() {
  const [isVerified, setIsVerified] = useState(false);
  const [openModal, setOpenModal] = useState('hidden');

  const handleOpenModal = () => {setOpenModal('flex')}
  const handleCloseModal = () => {setOpenModal('hidden')}

  const handleIsVerified = (value) => {
    setIsVerified(value);
    handleOpenModal();
  };

  const handleClose = () => {
    handleCloseModal()
  }

  const [subtitle, setSubtitle] = useState("Masukkan kode OTP yang anda terima pada nomor");

  const handleChangePhone = (value) => {
    setSubtitle("Masukkan kode OTP yang anda terima pada nomor " + value);
  }

  useEffect(() => {
    initFlowbite();
    return () => {};
  }, []);

  return (
    <>
      <div className="pt-24 xl:px-40 lg:px-20 px-6">
        <div className="grid grid-cols-5 w-full">
          <div className="md:block hidden col-span-1">
            <Sidebar />
          </div>
          <div className="md:col-span-4 col-span-5">
            <div className="pt-6 pb-10 px-10 border border-gray-900">
              <div className="mb-2">
                <h1 className="text-2xl font-semibold mb-1">Ubah Kata Sandi</h1>
                <p className="text-sm mb-6">
                  Anda dapat mengubah kata sandi untuk meningkatkan keamanan
                  akun anda{" "}
                </p>
                <hr className="border-gray-900 mb-6" />
              </div>
              {isVerified == false ? (
                <VerifUser getVerified={handleIsVerified} getPhone={handleChangePhone} />
              ) : (
                <ChangePassword getVerified={handleIsVerified} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        id="small-modal"
        tabindex="-1"
        className={"fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full blur-sm max-h-full justify-center items-center " + openModal}
        style={{ backgroundColor: 'rgba(68, 85, 85, 0.5)', backdropFilter:'blur(2px)' }}
      >
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white px-7 py-8">
            <div className="flex justify-end">
              <button type="button" onClick={handleClose} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <Otp title="Ubah Kata Sandi" subTitle={subtitle} closeModal={handleClose}/>
          </div>
        </div>
      </div>
    </>
  );
}
