import React from "react";

const termsData = [
  {
    id: "syarat",
    name: "Syarat & Ketentuan",
    childs: [
      {
        id: "syarat1",
        name: "Syarat 1",
      },
      {
        id: "syarat2",
        name: "Syarat 2",
      },
      {
        id: "syarat1",
        name: "Syarat 3",
      },
    ],
  },
  {
    id: "kebijakan",
    name: "Kebijakan Privasi",
    childs: [
      {
        id: "kebijakan1",
        name: "Kebijakan 1",
      },
      {
        id: "kebijakan2",
        name: "Kebijakan 2",
      },
      {
        id: "kebijakan1",
        name: "Kebijakan 3",
      },
    ],
  },
  {
    id: "general",
    name: "informasi Umum",
    childs: [
      {
        id: "info1",
        name: "Info 1",
      },
      {
        id: "info2",
        name: "Info 2",
      },
      {
        id: "info1",
        name: "Info 3",
      },
    ],
  },
];

export default function TermSidebar({onClickMenu}) {
const handleChangeMenu = (value) => {
    console.log(value);
    if(typeof onClickMenu == 'function'){
        onClickMenu(value);
    }
}


  return (
    <>
      <div>
        <div className="relative mt-3">
          {/* Accordion */}
          <div id="accordion-open" data-accordion="open">
            {termsData.map((item) => {
              return (
                <div className="relative" key={item.id}>
                  <h2>
                    <button
                      type="button"
                      className="flex items-center justify-between w-full p-1 font-medium text-left !bg-white !text-gray-900"
                      data-accordion-target={"#" + item.id}
                      aria-expanded="true"
                      aria-controls={item.id}
                    >
                      <span className="text-gray-900">{item.name}</span>
                      <svg
                        data-accordion-icon
                        className="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5 5 1 1 5"
                        />
                      </svg>
                    </button>
                  </h2>
                  <div
                    id={item.id}
                    className="hidden"
                    aria-labelledby={item.id}
                  >
                    <div className="p-3">
                      <ul className="w-full text-sm font-medium text-gray-900">
                        {item.childs.map(function (child) {
                          return (
                            <li className="w-full" key={child.id}>
                              <div className="flex items-center">
                                <input
                                  id={
                                    child.id +
                                    "_" +
                                    child.name
                                      .toLocaleLowerCase()
                                      .split(" ")
                                      .join("_")
                                  }
                                  onChange={(e) => handleChangeMenu(e.target.value)}
                                  type="radio"
                                  value={child.name}
                                  name="menu"
                                  className="hidden peer"
                                />
                                <label
                                  for={
                                    child.id +
                                    "_" +
                                    child.name
                                      .toLocaleLowerCase()
                                      .split(" ")
                                      .join("_")
                                  }
                                  className="w-full mb-1 ml-2 text-sm font-medium cursor-pointer text-gray-400 peer-checked:text-gray-900 hover:text-gray-900"
                                >
                                  {child.name}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
