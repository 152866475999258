import React from "react";

function formatToIDR(number) {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  });

  return formatter.format(number);
}

let grandTotal = 0;
export default function OrderList({products, salesData}) {
  return (
    <div className="md:border border-gray-300 md:p-4 p-0 rounded-sm mb-4">
      <h5 className="mb-3 text-xl font-semibold tracking-tight text-gray-900 md:block hidden">
        Rincian Pesanan Penyewaan
      </h5>
      <div>
        {
          (products.length > 0) ? products.map((item, index) => {
            if(index == 0){
              grandTotal = 0;
            }
            const subTotal = (item.qty > 0) ? parseInt(item.qty) * parseInt(item.price) : 0;
            grandTotal += subTotal;
            return (
              <div
                key={index}
                className="flex justify-between items-center mb-1 font-normal"
              >
                <p className="text-gray-900">
                  {item.catalog_name} ({item.product_name}) x {item.qty}
                </p>
                <p className="text-gray-900">{formatToIDR(subTotal)}</p>
              </div>
            );
          }) : ''
        }
        <div className="flex justify-between items-center mb-1 font-normal">
          <p className="text-gray-500">Termasuk PPN</p>
        </div>
        <div className="flex justify-between items-center mb-1 font-normal">
          <p className="text-gray-500">Termasuk Pengiriman</p>
        </div>
        <div className="xl:flex justify-between items-center mt-8 xl:text-lg font-semibold md:block hidden">
          <p className="text-gray-900">Total Pesanan</p>
          <p className="text-gray-900">{formatToIDR((products.length > 0) ? grandTotal : 0)}</p>
        </div>
        {
          (salesData != null && salesData.is_dp == 1) ? 
          <div className="xl:flex justify-between items-center mt-2 xl:text-lg font-semibold md:block hidden">
            <p className="text-gray-900">DP</p>
            <p className="text-gray-900">{formatToIDR((products.length > 0) ? (salesData.dp_value) : 0)}</p>
          </div> : ''
        }
      </div>
    </div>
  );
}
