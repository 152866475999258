import React, { useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import useAsync from "helpers/hooks/useAsync";
import fetch from "helpers/fetch";
import Carousel from "components/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {faWhatsappSquare} from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@iconify/react";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList, faWhatsappSquare);

var customColor = {
  gray: "#999",
  bgGray: "#F5F5F5"
};

var services = [
  {
    title: "Pembayaran Mudah",
    text: "Tersedia berbagai metode untuk pembayaran",
    icon: "streamline:money-cash-coins-stack-accounting-billing-payment-stack-cash-coins-currency-money-finance",
  },
  {
    title: "Proses Pengiriman Cepat",
    text: "Pengiriman dilakukan secepatnya",
    icon: "carbon:delivery",
  },
  {
    title: "Diskusi Via What’s App",
    text: "Penyewa dapat berdiskusi melalui WA dengan mudah",
    icon: "ic:baseline-whatsapp",
  },
  {
    title: "Pemasangan Busana Oleh Pihak Az Zahra",
    text: "Az Zahra akan membantu proses pemasangan busana",
    icon: "icon-park-outline:clothes-cardigan",
  },
];

function Loading() {
  return Array(6)
    .fill()
    .map((_, index) => {
      return (
        <div className="px-4 relative card group" key={index}>
          <div
            className="rounded-xl bg-gray-300 overflow-hidden card-shadow relative flex justify-center"
            style={{ width: 287, height: 386 }}
          >
            <div
              className="text-white absolute bottom-5 bg-text-card mx-auto flex justify-between px-3 py-1 rounded-md"
              style={{ minWidth: 200 }}
            >
              <div className="mr-3">
                <div className="w-24 h-3 bg-gray-300 mt-3 rounded-full"></div>
                <div className="w-36 h-3 bg-gray-300 mt-2 rounded-full"></div>
              </div>
            </div>
          </div>
        </div>
      );
    });
}

export default function Services() {
  return (
    <section className="flex flex-col pb-16 pt-32 xl:px-40 lg:px-20 px-6" id="services">
      <div className="container mx-auto mb-4">
        <div className="flex justify-center text-center mb-4">
          <h3 className="text-3xl capitalize font-semibold">
            Layanan Untukmu <br className="" />
            <span
              className="text-sm capitalized font-semibold"
              style={{ color: customColor.gray }}
            >
              Az Zahra Among menyediakan layanan untuk mempermudah proses penyewaan
            </span>
          </h3>
        </div>
      </div>
      <div className="overflow-x-hidden px-4" id="carousel">
        <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12">
          {services.map((item) => {
            return (
              <div
                className="rounded overflow-hidden service-card w-full h-full"
                key={item.title}
              >
                <div className="p-7">
                <Icon icon={item.icon} width="35" className="mb-3" />
                  <div className="font-medium text-xl mb-2">
                    {item.title}
                  </div>
                  <p className="text-base">
                    {item.text}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
