import React, { useEffect, useState } from "react";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import useAsync from "helpers/hooks/useAsync";
import fetchApi from "helpers/fetchApi";

export default function StorePassword({ data, toLogin, openFormPassword }) {
  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleLogin = () => {
    if (typeof toLogin == "function") {
      toLogin(false);
    }
  };

  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const handleChangePassword = () => {
    const password = document.getElementById("password").value;
    const password_confirmation = document.getElementById(
      "password_confirmation"
    ).value;

    if (password.length > 0 && password_confirmation.length > 0) {
      if (password != password_confirmation) {
        setIsPasswordMatch(false);
      } else {
        setIsPasswordMatch(true);
      }
    }else{
      setIsPasswordMatch(true);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    if (showPassword == false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleShowPasswordConfirm = () => {
    if (showPasswordConfirm == false) {
      setShowPasswordConfirm(true);
    } else {
      setShowPasswordConfirm(false);
    }
  };

  const userInput = useAsync();
  const [errorRegister, setErrorRegister] = useState(null);

  const handleRegister = async() => {
    setErrorRegister(null)
    const username = data;
    const password = document.getElementById("password").value;
    const password_confirmation = document.getElementById(
      "password_confirmation"
    ).value;

    const userData = {
      store_password : 1,
      username : username,
      password : password,
      password_confirmation : password_confirmation
    }

    console.log(userData);

    await userInput.run(fetchApi({ url: "/auth/register", method:"POST", body: userData}));
    
    if(userInput.error){
      setErrorRegister(userInput.error)
    }
  };

  useEffect(() => {
    if(userInput.data != undefined && userInput.data != null){
      if(userInput.data.status == true){  
        handleLogin();
      }else{
        setErrorRegister(userInput.data.message)
      }
    }
  }, [userInput.data])
  

  const handleCloseStorePassword = () => {
    if(typeof openFormPassword == 'function'){
      openFormPassword(false);
    }
  }

  return (
    <div className="w-full h-full">
      <div className="relative mb-7">
        <Icon
          icon="mdi:navigate-before"
          className="text-gray-800 cursor-pointer absolute"
          style={{ top: "-2px" }}
          width="40"
          onClick={handleCloseStorePassword}
        />
        <h1 className="text-center text-2xl font-medium">Create Password</h1>
      </div>
      {
          (errorRegister != null) ? 
          <>
           <div class="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
              <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Error</span>
              <div>
                <span class="font-medium">Error!</span> {errorRegister}.
              </div>
            </div>
          </> : ''
        }
      <div>
        <div className="relative mb-3">
          <input
            type={showPassword == false ? "password" : "text"}
            id="password"
            class="text-gray-800 w-full border border-gray-400 font-light"
            placeholder="Kata Sandi"
            onChange={handleChangePassword}
            required
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
            <Icon
              icon="mdi:eye-outline"
              className={["cursor-pointer", showPassword == false ? "block" : "hidden"].join(" ")}
              onClick={handleShowPassword}
              width="23"
              color="#999999"
            />
            <Icon
              icon="mdi:eye-off-outline"
              className={["cursor-pointer", showPassword == false ? "hidden" : "show"].join(" ")}
              onClick={handleShowPassword}
              width="23"
              color="#999999"
            />
          </div>
        </div>
        <div className="relative mb-3">
          <input
            type={showPasswordConfirm == false ? "password" : "text"}
            id="password_confirmation"
            class={[
              "text-gray-800 w-full border font-light",
              isPasswordMatch == false ? "border-red-600" : "border-gray-400",
            ].join(" ")}
            placeholder="Konfirmasi Kata Sandi"
            onChange={handleChangePassword}
            required
          />
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
            <Icon
              icon="mdi:eye-outline"
              className={["cursor-pointer", showPasswordConfirm == false ? "block" : "hidden"].join(" ")}
              onClick={handleShowPasswordConfirm}
              width="23"
              color="#999999"
            />
            <Icon
              icon="mdi:eye-off-outline"
              className={["cursor-pointer", showPasswordConfirm == false ? "hidden" : "show"].join(" ")}
              onClick={handleShowPasswordConfirm}
              width="23"
              color="#999999"
            />
          </div>
        </div>
        {isPasswordMatch == false ? (
          <p class="mt-2 text-sm text-red-600 dark:text-red-500">
            <span class="font-medium">Oops!</span> Password Confirmation doesn't
            match!
          </p>
        ) : (
          ""
        )}
        <Button
          size="sm"
          className="w-full py-2 rounded-sm mt-5 mb-2"
          style={{
            backgroundColor: "#56A9B7",
            hover: {
              backgroundColor: "white",
              color: "#56A9B7",
            },
          }}
          onClick={handleRegister}
        >
          <span className="text-base font-light">Buat Pengguna</span>
        </Button>
      </div>
      <div className="mt-5">
        <p className="text-center text-gray-500" style={{ fontSize: "13px" }}>
          Sudah Memiliki Akun?{" "}
          <span className="text-blue-800 cursor-pointer" onClick={handleLogin}>
            Login
          </span>
        </p>
      </div>
    </div>
  );
}
