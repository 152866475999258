import React from "react";
import { Link } from "react-router-dom";
import { Button } from "flowbite-react";

export default function OrderButton({ buttonParams, onPayment, disabled = false}) {
  const handleOnProcess = () => {
    if(typeof onPayment == 'function'){
        onPayment(true);
    }
  };

  const button =
  <>
    <Button
      onClick={handleOnProcess}
      size="lg"
      className="w-full py-2 rounded-sm disabled:bg-gray-400 disabled:text-gray-800 disabled:hover:text-gray-800"
      disabled={disabled}
      style={{
        backgroundColor: "#56A9B7",
        disabled: {
          backgroundColor: "rgba(156 163 175, 1)"
        },
        hover: {
          backgroundColor: "white",
          color: "#56A9B7",
        },
      }}
    >
          <span className="text-xl">{buttonParams.label}</span>
    </Button>
  </>

  return (
    <>
      <div className="mb-4">
        <h5 className="mb-3 text-xl font-semibold tracking-tight text-gray-900">
          Ketentuan Penggunaan
        </h5>
        <p className="mb-2">
          Dengan melakukan pembayaran persewaan, anda dianggap telah memahami
          dan menyetujui ketentuan kami.
        </p>
        <h5 className="mb-3 text-md font-semibold tracking-tight text-gray-900">
          Ketentuan Penggunaan
        </h5>
      </div>
      <div>
        {
          (disabled == false && buttonParams.url != null) ?
          <Link to={(buttonParams.url != null) ? buttonParams.url : "#"}
              onClick={handleOnProcess}
          >
            {button}
          </Link> : button
        }
        <p className="font-normal mt-2 text-sm  text-gray-500">
          Anda dapat melakukan ajukan sewa untuk mendapatkan verifikasi dari
          kami. Pembayaran dapat dilanjutkan setelah mendapatkan verifikasi.
        </p>
      </div>
    </>
  );
}
