import Footer from "parts/Footer";
import Header from "parts/Header";
import Sitemap from "parts/Sitemap";
import TransactionsSection from "parts/Transactions/TransactionsSection";
import React, { useEffect } from "react";

const sales = [
  {
    customer_name: "Ardi",
    phone: "0983019283091",
    address: "Jl. Soeta Surabaya Rumah Hijau",
    status: 1,
    sales_date: "4 September 2023",
    delivery_fee: 6000,
    service_fee: 1000,
    sub_total: 180000,
    grand_total: 187000,
    detail: [
      {
        catalog_name: "Among Jawa Barat Untuk Pria",
        image: "/images/products/among.png",
        size: "XL",
        qty: 10,
        variant: "Motif Bunga",
        sub_total: 120000,
      },
    ],
  },
  {
    customer_name: "Ardi",
    phone: "0983019283091",
    address: "Jl. Soeta Surabaya Rumah Hijau",
    status: 2,
    sales_date: "5 September 2023",
    delivery_fee: 6000,
    service_fee: 1000,
    sub_total: 180000,
    grand_total: 187000,
    detail: [
      {
        catalog_name: "Among Jawa Barat Untuk Pria",
        image: "/images/products/among.png",
        size: "XL",
        qty: 10,
        variant: "Motif Bunga",
        sub_total: 120000,
      },
      {
        catalog_name: "Among Jawa Barat Untuk Pria",
        image: "/images/products/among.png",
        size: "L",
        qty: 5,
        variant: "Motif Bunga",
        sub_total: 60000,
      },
    ],
  },
  {
    customer_name: "Ardi",
    phone: "0983019283091",
    address: "Jl. Soeta Surabaya Rumah Hijau",
    status: 3,
    sales_date: "5 September 2023",
    delivery_fee: 6000,
    service_fee: 1000,
    sub_total: 180000,
    grand_total: 187000,
    detail: [
      {
        catalog_name: "Among Jawa Barat Untuk Pria",
        image: "/images/products/among.png",
        size: "L",
        qty: 5,
        variant: "Motif Bunga",
        sub_total: 60000,
      },
    ],
  },
  {
    customer_name: "Ardi",
    phone: "0983019283091",
    address: "Jl. Soeta Surabaya Rumah Hijau",
    status: 4,
    sales_date: "6 September 2023",
    delivery_fee: 6000,
    service_fee: 1000,
    sub_total: 180000,
    grand_total: 187000,
    detail: [
      {
        catalog_name: "Among Jawa Barat Untuk Pria",
        image: "/images/products/among.png",
        size: "L",
        qty: 5,
        variant: "Motif Bunga",
        sub_total: 60000,
      },
    ],
  },
  {
    customer_name: "Ardi",
    phone: "0983019283091",
    address: "Jl. Soeta Surabaya Rumah Hijau",
    status: 0,
    sales_date: "6 September 2023",
    delivery_fee: 6000,
    service_fee: 1000,
    sub_total: 180000,
    grand_total: 187000,
    detail: [
      {
        catalog_name: "Among Jawa Barat Untuk Pria",
        image: "/images/products/among.png",
        size: "L",
        qty: 5,
        variant: "Motif Bunga",
        sub_total: 60000,
      },
    ],
  },
];


export default function Transactions() {
  useEffect(() => {
    window.scrollTo(0,0)
   }, [])
   
  return (
    <>
      <Header />
      <TransactionsSection {...sales} />
      <Sitemap />
      <Footer />
    </>
  );
}
