import { Icon } from "@iconify/react";
import { Button } from "flowbite-react";
import useAsync from "helpers/hooks/useAsync";
import React, { useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import fetchApi from "helpers/fetchApi";

export default function Otp({
  title,
  subTitle,
  closeModal,
  toLogin,
  toRegister,
  backButton,
  data = {}
}) {
  
  const [otpData, setOtpData] = useState({});
  
  const handleLogin = () => {
    if (typeof toLogin == "function") {
      toLogin(false);
    }
  };

  const handleRegister = (status, value) => {
    if (typeof toRegister == "function") {
      toRegister(status, value  );
    }
  };

  const handleNext = () => {
    if (typeof closeModal == "function") {
      closeModal();
    }
  }

  const [otp, setOtp] = useState("");

  const verifOtp = useAsync();

  const handleVerifOtp = () => {
    if(otpData.user_id != null && otp != ""){
      const data = {
        user_id : otpData.user_id,
        otp : otp
      }
      
      verifOtp.run(fetchApi({ url: "/verif-otp", method:"POST", body: data}));
    }
  }

  useEffect(() => {
    setOtpData(data);
  }, [data])

  useEffect(() => {
    if(verifOtp.data != undefined  && verifOtp.data != null){
      if(verifOtp.data.status == true){
        handleRegister(true, verifOtp.data.data.username)
        handleNext()
      }else{
        console.log(verifOtp.data);
      }
    }
  }, [verifOtp.data])

  const otpRequest = useAsync();
  const handleOtpRequest = async () =>{
    const data = {user_id: otpData.user_id}
    await otpRequest.run(fetchApi({ url: "/generate-otp", method:"POST", body: data}));
    
    if(otpRequest.data != undefined && otpRequest.data != null){
      if(otpRequest.data.status == true){
        const dataOtp = {
          user_id : otpRequest.data.data.user_id,
          exp : otpRequest.data.data.expire_at
        }

        setOtpData(dataOtp);
      }
    }
  }

  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (value) => {
    const diff = new Date(value) - new Date();
    const seconds = Math.floor((diff / 1000) % 60);
    const minutes = Math.floor((diff / 1000 / 60) % 60);

    return {
      diff,
      minutes,
      seconds
    }
  }

  const startTimer = (value) => {
    let { diff, minutes, seconds } = getTimeRemaining(value);
    if (diff >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        setTimer(
              (minutes > 9
                    ? minutes
                    : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
        );
    }
  };

  useEffect(() => {
    if(otpData.exp != undefined && otpData.exp != null){
      const interval = setInterval(() => startTimer(otpData.exp), 1000);

      return () => clearInterval(interval);
    }
  }, [otpData])

  return (
    <>
      <div className="h-full w-full">
        <div className="relative mb-5">
          {backButton == true ? (
            <Icon
              icon="mdi:navigate-before"
              className="text-gray-800 cursor-pointer absolute"
              style={{ top: "-2px" }}
              width="40"
              onClick={() => handleRegister(true, null)}
            />
          ) : (
            ""
          )}
          <h1 className="text-center text-2xl font-medium">{title}</h1>
        </div>
        <div>
          <p className="text-center mb-2 text-gray-400 font-light text-sm">
            {subTitle}
          </p>

          <div className="flex justify-center mb-3">
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              shouldAutoFocus
              inputStyle={{
                paddingRight: "0.2rem",
                paddingLeft: "0.1rem",
                width: "2em",
              }}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <p className="text-gray-400 font-normal text-center mb-6 text-xs">
            {timer}
          </p>
          <p className="text-gray-400 font-normal text-center mb-2 text-xs">
            <span onClick={handleOtpRequest} className="hover:text-gray-700 cursor-pointer">Minta Kode Baru</span>
          </p>

          <Button
            size="sm"
            className="w-full py-2 rounded-sm mb-2"
            style={{
              backgroundColor: "#56A9B7",
              hover: {
                backgroundColor: "white",
                color: "#56A9B7",
              },
            }}

            onClick={handleVerifOtp}
          >
            <span className="text-base font-light">Berikutnya</span>
          </Button>
        </div>
        <div className="mt-5">
          <p className="text-center text-gray-500" style={{ fontSize: "13px" }}>
            Sudah Memiliki Akun?{" "}
            <span
              className="text-blue-800 cursor-pointer"
              onClick={handleLogin}
            >
              Login
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
