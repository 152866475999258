import React from "react";
import Footer from "parts/Footer";
import Header from "parts/Header";
import Sitemap from "parts/Sitemap";
import ForgetCard from "parts/ForgetPassword/ForgetCard";

export default function ForgetPassword() {
  return (
    <>
      <Header />
      <ForgetCard />
      <Sitemap />
      <Footer />
    </>
  );
}
