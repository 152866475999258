import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";

const FailedModal = (show = false, message = "Sorry, Can not submit transacion, please try again later or contact administrator") => {
    const [showSuccessModal, setShowModal] = useState(show);
    const [timerSuccessModal, setTimerModal] = useState(0);

    let interval = undefined;
  
    useEffect(() => {
      if(showSuccessModal){
        if(timerSuccessModal <= 3){
          interval = setInterval(() => {
            setTimerModal((prev) => prev + 1);
          }, 1000);
        }else{
            setShowModal(false);
            clearInterval(interval)
            setTimerModal(0)
        }
      }
      console.log('use effect')
      console.log(showSuccessModal)
    }, [showSuccessModal, message])

    return (
        <>
            {
                (showSuccessModal == true) ?  
                <div className="h-screen w-screen top-0 left-0 flex justify-center items-center fixed z-50">
                <div className="flex flex-col items-center bg-gray-800/80 w-72 p-4">
                    <Icon icon="lets-icons:check-fill" color="#56a9b7" width={70} className="mb-4"/>
                    <p className="text-md text-center text-white">{message}</p>
                </div>
                </div> : '' 
            }
        </>
    )
}

export default FailedModal;