import React, { useEffect } from "react";
import { initFlowbite } from "flowbite";
import { Icon } from "@iconify/react";

export default function Testimony() {
  const testimonies = [
    {
      photo: "/images/profile/profile1.png",
      client_name: "Seivable",
      comment:
        "Saya sangat suka dengan pelayanan dari Az Zahra Among, mulai dari pemasangan busana hingga pembayaran dan pengiriman yang cepat dan tanggung jawab",
    },
    {
      photo: "/images/profile/profile2.png",
      client_name: "Susi",
      comment: "Saya sangat suka dengan pelayanan bajunya bagus",
    },
    {
      photo: "/images/profile/profile3.png",
      client_name: "Sindi",
      comment: "Nice kak, pelayanan bagus pengiriman cepat, sangat recommended",
    },
  ];

  useEffect(() => {
    initFlowbite();
    return () => {};
  }, []);
  return (
    <>
      <section className="py-2 xl:px-40 lg:px-20 px-6">
        <div
          id="animation-carousel"
          className="relative w-full"
          data-carousel="static"
          data-carousel-interval="5000"
        >
          <div className="relative overflow-hidden rounded-lg md:h-96 h-screen z-0">
            {testimonies.map((item) => {
              return (
                <div
                  className="hidden duration-700 ease-linear h-full w-full bg-white z-10"
                  data-carousel-item
                  key={item.client_name}
                >
                  <div className="flex flex-col items-center h-full w-full shadow md:flex-row ">
                    <div className="md:h-2/3 md:w-1/4 w-full h-96 overflow-hidden flex justify-center">
                      <img
                        className="object-cover md:h-full"
                        src={item.photo}
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col justify-between p-4 leading-normal md:w-3/4">
                      <h5 className="mb-3 text-2xl font-semibold tracking-tight text-gray-900">
                        Testimoni
                      </h5>
                      <p className="mb-3 text-xl font-normal text-gray-900 italic">
                        “{item.comment}”
                      </p>
                      <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900">
                        - {item.client_name}
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-center md:mt-0 -mt-28">
            <button
              type="button"
              className="left-0 z-30 flex items-center justify-center cursor-pointer group focus:outline-none"
              data-carousel-prev
            >
              <span className="inline-flex items-center justify-center mr-4 w-10 h-10 group-focus:outline-none text-xl text-gray-400 hover:text-gray-900">
                <Icon icon="ic:round-keyboard-backspace" width="30" />
                <span className="sr-only">Previous</span>
              </span>
            </button>
            <button
              type="button"
              className="right-0 z-30 flex items-center justify-center cursor-pointer group focus:outline-none"
              data-carousel-next
            >
              <span className="inline-flex items-center justify-center ml-4 w-10 h-10 group-focus:outline-none text-xl text-gray-400 hover:text-gray-900">
                <Icon
                  icon="ic:round-keyboard-backspace"
                  width="30"
                  rotate={2}
                />
                <span className="sr-only">Next</span>
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
