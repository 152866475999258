import React, { useEffect, useState } from "react";
import ProductList from "./ProductList";
import Filter from "./Filter";
import { Icon } from "@iconify/react";
import AnimateHeight from "react-animate-height";
import useWindowDimensions from "helpers/hooks/useWindowDimensions";
import { useSearchParams } from "react-router-dom";

export default function SearchResult({keyword}) {

  const [categoryFilter, setCategoryFilter] = useState(null);
  const [priceFilter, setPriceFilter] = useState(null);
  const [search, setSearch] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get('category');

  const getFilter = (category, price) => {
    setCategoryFilter(category);
    setPriceFilter(price)
    if(search == false){
      setSearch(true)
    }else{
      setSearch(false)
    }
  }

  const { height, width } = useWindowDimensions();

  const [toggleMobileFilter, setToggleMobileFilter] = useState(0);

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
   if(categoryParam != null){
    setCategoryFilter(categoryParam)
   }
  }, [categoryParam])
  
  

  return (
    <>
      <section className="product-list pb-16 pt-28 xl:px-40 lg:px-20 px-6">
        <div className="grid grid-cols-4 gap-4">
          <div className="md:col-span-1 md:relative fixed z-20 md:pr-4">
            <Filter getFilter={getFilter} toggleOpenMenu={toggleMobileFilter} handleCloseMobileFilter={setToggleMobileFilter} category={categoryFilter}/>
          </div>
          <div className="md:col-span-3 col-span-4">
            <div className="flex md:justify-end justify-between mb-16">
              <div>
                <h4 className="font-semibold mb-3">Pilih Berdasarkan</h4>
                <select
                  id="sort_product"
                  class="border-2 border-black focus:border-black outline-none focus:outline-none text-sm font-semibold block p-2.5 "
                >
                  <option selected>Produk Unggulan</option>
                  <option value="US">Rendah - Tinggi</option>
                  <option value="CA">Tinggi - Rendah</option>
                  <option value="FR">Rating Tertinggi</option>
                </select>
              </div>
              <button className="md:hidden block"  onClick={() =>
                setToggleMobileFilter(toggleMobileFilter === 0 ? "auto" : 0)
              }><Icon icon="mi:filter" width="40"/></button>
            </div>
            <ProductList keyword={keyword} category={categoryFilter} price={priceFilter} trigger={search}/>
          </div>
        </div>
      </section>
    </>
  );
}
