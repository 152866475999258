import React, { useEffect, useState } from "react";
import PaymentInfo from "./PaymentInfo";
import PaymentDetail from "./PaymentDetail";
import AnimateHeight from "react-animate-height";
import { Icon } from "@iconify/react";
import OrderList from "parts/OrderList";
import useAsync from "helpers/hooks/useAsync";
import fetchApi from "helpers/fetchApi";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import fetchData from "helpers/fetchApi";
import LoadingScreen from "parts/LoadingScreen";
import FailedModal from "./FailedModal";
import formatToIDR from "parts/formatToIDR";

export default function PaymentSection({address, name, phone}) {
  const navigate = useNavigate();
  const [pay, setPay] = useState(false);
  const [paid, setPaid] = useState(false);
  const [buttonParams, setButtonParams] = useState({
    url: null,
    label: "Ajukan Sewa",
  });
  
  const [openMobileDetail, setOpenMobileDetail] = useState(0);

  const handleOpenMobileDetail = () => {
    if(openMobileDetail  == 0){
      setOpenMobileDetail('auto');
    }else{
      setOpenMobileDetail(0);
    }
  }

  const nullParams = [null, undefined, 0, 'null'];
  const [cookies, setCookie, removeCookie] = useCookies();
  const [userInfo, setUserInfo] = useState({full_name : name, phone: phone, address: address});

  const [searchParams, setSearchParams] = useSearchParams();
  const salesId = searchParams.get('sales');
  const [salesData, setSalesData] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [products, setProducts] = useState([]);
  const [paymentType, setPaymentType] = useState(null);

  const salesRequest = useAsync();
  useEffect(() => {
    salesRequest.run(fetchData({url: '/sales/' + salesId, token: cookies.token}))
  }, [salesRequest.run,salesId])

  useEffect(() => {
    if(!nullParams.includes(salesRequest.data)){
      if(salesRequest.data.data){
        console.log(salesRequest.data.data);
        setSalesData(salesRequest.data.data);
        setEventDate(salesRequest.data.data.sales_date)
        setUserInfo({full_name : salesRequest.data.data.user.full_name, phone: salesRequest.data.data.user.phone, address: salesRequest.data.data.address})
        setProducts(salesRequest.data.data.detail)
        setPay(true);
        if(salesRequest.data.data.status > 3){
          setButtonParams({
            url: '/transaction/detail/' + salesRequest.data.data.sales_id, 
            label : 'Lihat Pesanan'
          })
        }else{
          setButtonParams({
            url: null, 
            label : 'Bayar Pesanan'
          })
        }
        
      }
    }
  }, [salesRequest.data])
  

  const userInfoRequest = useAsync();
  const [userId, setUserId] = useState();

  useEffect (() => {
    window.scrollTo(0,0);
  })

  useEffect(() => {
    const data = {username : cookies.username};
    console.log(data);
    userInfoRequest.run(fetchApi({ url: "/profile?username=" + cookies.username, token: cookies.token}));
  }, [userInfoRequest.run, cookies]);

  useEffect(() => {
    if(userInfoRequest.data != undefined && userInfoRequest.data != null){
      console.log(userInfoRequest.data);
      if(userInfoRequest.data.data != null){
        setUserId(userInfoRequest.data.data.id)
      }else{
        setUserId(null)
      }
    }else{
      setUserId(null)
    }
  }, [userInfoRequest.data])

  // get cart data
  const cart = useAsync();

  useEffect(() => {
    cart.run(fetchApi({ url: "/cart/" + userId, token: cookies.token}));
  }, [cart.run, userId]);

  useEffect(() => {
    if(salesId == null){
      if((cart.data != undefined && cart.data != null)){
        if((cart.data.status == true && cart.data.data.length > 0)){
          const defaultSelectedCart = [];
          cart.data.data.map((item) => {
            if(item.status == 1){
              defaultSelectedCart.push(item)
            }
          })
          setProducts(defaultSelectedCart);
        }else{
          setProducts([])
        }
      }else{
        setProducts([])
      }
    }
  }, [cart.data]);

  const handleGetDate = (date) => {
    setEventDate(date);
  }

  const handleGetPaymentType = (type) => {
    setPaymentType(type)
  }

  const [uploadImage, setUploadImage] = useState();

  const handleSetImage = (image) =>{
    setUploadImage(image)
  }

  const salesInput = useAsync();
  const handleSubmitSales = async() =>{
    const data = {user_id : userId, event_date: eventDate, address: address, payment_type: paymentType};
    const response = await salesInput.run(fetchApi({ url: "/sales", method:"POST", body: data, token: cookies.token}));
    return response;
  }

  const paymentSales = useAsync();
  const handlePaySales = async() => {
    const data = {sales_id: salesData.sales_id, payment_method: "LUNAS", transaction_id: null, path_photo: uploadImage}

    const dataForm = new FormData()
    dataForm.append('sales_id', salesData.sales_id)
    dataForm.append('payment_method', "BCA")
    if(salesData.is_dp == 1){
      dataForm.append('payment_type', 0)
    }else{
      dataForm.append('payment_type', 1)
    }
    dataForm.append('transaction_id', null);
    dataForm.append('path_photo', uploadImage);

    const response = await paymentSales.run(fetchApi({ url: "/payment/" + salesData.sales_id, method:"POST", body: dataForm, token: cookies.token, content_type: 'multipart/form-data'}));
    return response;
  }
  
  const [loading, setLoading] = useState(false);
  const [loadProgress, setLoadProgress] = useState(5);

  useEffect(() => {
    if(loadProgress  >= 100){
      setLoading(false);
    }
  }, [loadProgress])

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [timerSuccessModal, setTimerSuccessModal] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);

  let interval = undefined;

  useEffect(() => {
    if(showSuccessModal){
      if(timerSuccessModal <= 3){
        interval = setInterval(() => {
          setTimerSuccessModal((prev) => prev + 1);
        }, 1000);
      }else{
        setShowSuccessModal(false);
        clearInterval(interval)
        setTimerSuccessModal(0)
      }
    }
  }, [showSuccessModal, timerSuccessModal])

  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleProcess = async (value) => {
    setLoading(true);
    console.log('process')
    if(pay == false){
      const response = await handleSubmitSales()
      console.log(response)
      setLoadProgress(100)
      if(response){
        if(response.status == true){
          navigate('/transaction/detail/' + response.sales_id)
          // setPay(value);
          // setButtonParams({
          //     url: '/transaction/detail/' + response.sales_id, 
          //     label : 'Lihat Pesanan',
          //     disabled: true,
          // })
        }else{
          setShowFailedModal(true)
          return;
        }
      }else{
        setShowFailedModal(true)
        return;
      }
    }else{
      const response = await handlePaySales();
      console.log(response)
      if(response){
        if(response.status == true){
          setLoadProgress(100)
          setShowSuccessModal(true);
          setSuccessMessage("Berhasil membayar pesanan")
          setButtonParams({
            url: '/transaction/detail/' + salesId, 
            label : 'Lihat Pesanan'
          })
        }
      }
    }
  };

  const [grandTotal, setGrandTotal] = useState(0);
  useEffect(() => {
      if(products.length > 0){
        let gt = 0;
        products.forEach((item) =>{
          gt += (item.qty > 0) ? parseInt(item.qty) * parseInt(item.price) : 0;
        })
        setGrandTotal(gt)
      }else{
        setGrandTotal(0)
      }
  }, [products])

  const detailInfo = 
    <>
      <div className="md:hidden block fixed w-full z-30 bottom-0 left-0">
            <AnimateHeight duration={500} height={openMobileDetail}>
              <div className="max-h-80 overflow-y-auto bg-white px-5 py-2 pb-6" style={{ boxShadow:'0px 0px 7px 0px rgba(0,0,0,0.6)' }}>
                <div className="flex justify-between my-3">
                  <h3 className="text-lg font-semibold">
                    Rincian Pesanan Penyewaan
                  </h3>
                  <button
                    onClick={() => handleOpenMobileDetail()}
                  >
                    <Icon icon="charm:cross" height={25} />
                  </button>
                </div>
                <div>
                  <OrderList products={products} />
                </div>
              </div>
            </AnimateHeight>
            <div className="grid grid-cols-6 w-full h-14">
              <div className="col-span-4 h-14">
                  <div className="border-2 bg-white border-black h-full ">
                    <button className="w-full h-14 flex justify-center items-center text-sm font-semibold" onClick={handleOpenMobileDetail}>
                        <div className="flex justify-between w-full items-center px-5">
                          <div className="flex items-center justify-between w-full h-14">
                            <h3 className="text-sm font-semibold text-center">Total Harga</h3>
                            <h3 className="text-sm font-semibold text-center">{formatToIDR(grandTotal)}</h3>
                          </div>
                          <Icon icon="mingcute:up-line" width={28} className="ml-2"/>
                        </div>
                    </button>
                  </div>
              </div>
              <div className="col-span-2 h-14 ">
                  <button className="w-full h-full text-white text-sm font-semibold"  style={{ background: "#56A9B7", borderColor: "#56A9B7" }} onClick={() => handleProcess()}>
                      Ajukan Sewa
                  </button>
              </div>
            </div>
        </div>
    </>

  
  return (
    <>
     {
        (loading == true) ? <LoadingScreen progress={loadProgress}/> : ''
      }
    {
      (showSuccessModal == true) ?  
      <div className="h-screen w-screen top-0 left-0 flex justify-center items-center fixed z-50">
        <div className="flex flex-col items-center bg-gray-800/80 w-72 p-4">
          <Icon icon="lets-icons:check-fill" color="#56a9b7" width={70} className="mb-4"/>
          <p className="text-md text-center text-white">{successMessage}</p>
        </div>
      </div> : '' 
    }

    <FailedModal show={showFailedModal} />
    
    {
      (userInfo != null) ?
      <>
          <div className="xl:px-40 lg:px-20 px-5 pt-24">
          <div className="grid grid-cols-6 md:gap-8">
            <div className="md:col-span-4 col-span-6">
              <PaymentDetail isPay={pay} address={userInfo.address} name={userInfo.full_name} phone={userInfo.phone} getDate={handleGetDate} getPaymentType={handleGetPaymentType} salesData={salesData} eventDate={eventDate} setImage={handleSetImage}/>
            </div>
            <div className="md:block hidden col-span-2">
              <PaymentInfo products={products} onProses={handleProcess} buttonParams={buttonParams} id={userId} date={eventDate} uploadImage={uploadImage} salesData={salesData}/>
            </div>
          </div>
        </div>
        {detailInfo}
      </> : ''
    }
    </>
  );
}
