export default function fetchData({
    url,
    method = "GET",
    host = process.env.REACT_APP_BACKEND_API,
    token = null,
    body = {},
    content_type = "application/json" 
  }) {
    let headers;
    if(content_type == "application/json"){
      headers = { 
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        "Content-Type": content_type, 
        "Authorization" : "Bearer " + token
      };
    }else{
      headers = {
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        "Authorization" : "Bearer " + token
      }
    }
    
    return fetch(`${host}${url}`, {
      method,
      mode: "cors",
      headers: headers,
      body: (content_type == 'application/json') ? (Object.keys(body).length) ? JSON.stringify(body) : null : body
    }).then(async (response) => {
      const jsonResponse = await response.json();
      if (response.ok) return jsonResponse;
  
      throw new Error(JSON.stringify(jsonResponse));
    });
  }
  